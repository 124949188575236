import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerModel } from "@apiModels/customerModel";
import { BiCountryId } from "@enums/BiLanguageAndCountryId";
import { CustomerService } from "@globals/services/customer.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { kundeStatusItemsWithAll } from "@shared/interfaces-and-enums/shared-data";
import { PrimeNgUtilities } from "@shared/variables-and-functions/primeNg-utilities";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { Table } from "primeng/table";
import { Observable, finalize, of, take } from "rxjs";

@UntilDestroy()
@Component({
  templateUrl: "./superadmin-customerlist.component.html",
  styleUrls: ["./superadmin-customerlist.component.scss"],
  providers: [MessageService, ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuperAdminCustomerListComponent implements OnInit {
  @ViewChild("table") table: Table;

  public loading = true;

  public customers: Array<CustomerModel> = [];
  public customers$: Observable<Array<CustomerModel>>;
  private fullCustomerList: CustomerModel[] = [];

  filterStatusItems = kundeStatusItemsWithAll;
  selectedfilterStatusItems: SelectItem[] = [];

  public countries: Array<SelectItem> = [
    { value: BiCountryId.DK, label: "Danmark" },
    { value: BiCountryId.SE, label: "Sverige" },
    { value: BiCountryId.FI, label: "Finland" },
    { value: BiCountryId.NO, label: "Norge" }
  ];

  public selectedCountryCode = this.countries[0];

  selectedValue: string;

  private countryId = BiCountryId.DK;

  selectedCustomer: CustomerModel;

  cols: any[];

  showDeleted: boolean;

  constructor(
    private customerService: CustomerService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.selectedfilterStatusItems = this.filterStatusItems;

    const currentCustomer = this.customerService.getCurrentStateValue().currentCustomer;
    this.countryId = currentCustomer.countryId;
    this.showDeleted = false;

    this.initializeCustomers();

    this.cols = [
      { field: "id", header: "Id" },
      { field: "name", header: "Navn" },
      { field: "status", header: "Status" },
      { field: "address", header: "Adresse" },
      { field: "phone", header: "Telefon" },
      { field: "email", header: "Mail" },
      { field: "companyRegistrationId", header: "Cvr" }
    ];
  }

  onRowSelect(event) {
    this.router.navigate([event.data.id, "main"], { relativeTo: this.activeRoute });
  }

  public onCreateNewCustomer() {
    this.router.navigate([0, "main"], { relativeTo: this.activeRoute });
  }

  public selectedCountryCodeChange(item: SelectItem) {
    this.countryId = +item.value;
    this.initializeCustomers();
  }

  public onFilterStatusChange(selectedStatuses: SelectItem[]) {
    if (!selectedStatuses || selectedStatuses.length === 0) {
      // Show all customers if no statuses are selected
      this.customers$ = of(this.fullCustomerList);
    } else {
      // Extract the values from the selected statuses
      const selectedValues = selectedStatuses.map(status => status.value);

      // Filter customers based on the extracted values
      const filteredCustomers = this.fullCustomerList.filter(customer => selectedValues.includes(customer.status));

      this.customers$ = of(filteredCustomers);
    }
  }

  public onShowActiveChange(checked: boolean) {
    if (checked) {
      this.showDeleted = true;
      this.initializeCustomers();
    } else {
      this.showDeleted = false;
      this.initializeCustomers();
    }
  }

  private initializeCustomers() {
    this.customerService
      .getCustomers(this.countryId, this.showDeleted)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.loading = false;
          this.cd.detectChanges();
        })
      )
      .subscribe(customers => {
        this.fullCustomerList = customers; // Store the full list for filtering
        this.customers$ = of(customers); // Show all customers initially
      });
  }

  public exportToExcel() {
    PrimeNgUtilities.exportCSV(this.table, this.cols, { bom: true });
  }

  sendMail(messageId: string) {
    if (messageId) {
      this.customerService
        .sendMail(+messageId)
        .pipe(take(1))
        .subscribe(c => {
          console.log(c);
        });
    }
  }
}
