// import { Injectable } from "@angular/core";
// import { BiStore } from "@globals/classes/BiStore";
// import { Customer } from "@models/customer/Customer";
// import { Observable } from "rxjs";

// @Injectable({
//   providedIn: "root"
// })

// class CustomerState {
//   currentCustomer: Customer;
//   countryToCustomersMap: { [countryId: number]: Array<Customer> } = {};
// }

// export class CustomerService extends BiStore<CustomerState> {

//   constructor() {
//     ///
//   }

//   public userHasLoggedIn(): boolean {
//     return true;// !isStringNullOrEmpty(this.authService.refreshToken());
//   }

//   public getCustomer(id?: number, publicId?: string): Observable<Customer> {
//     return null;
//   }

// }

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
//import { Customer } from "@apiModels/Customer";
//import { CustomerEdit } from "@models/customer/CustomerEdit";
import { BiStore } from "@globals/classes/BiStore";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { cloneObject, getLanguageCodeByLanguageId } from "@globals/helper-functions";
import { CustomerUserRole } from "@models/user/UserRole";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { isStringNullOrEmpty, setObjectValueByString } from "@shared/variables-and-functions/helper-functions";
import moment from "moment-timezone";
import { Observable, throwError as observableThrowError } from "rxjs";
import { catchError, map, take, tap } from "rxjs/operators";
import { GlobalStateAndEventsService } from "../../src/core/global-state-and-events.service";
//import { CustomerEdit } from "@models/customer/CustomerEdit";
import { CustomerModel } from "@apiModels/customerModel";
import { EmailModel } from "@apiModels/emailModel";
//import { ElprisModel } from "@apiModels/elprisModel";
import { EmailAttachmentModel } from "@apiModels/emailAttachmentModel";
import { FarmDto } from "@apiModels/farmDto";
import { LocaleStringResource } from "@apiModels/localeStringResource";
import { UserEditModel } from "@apiModels/userEditModel";
import { WikiDocumentDto } from "@apiModels/wikiDocumentDto";
import { TranslateService } from "@ngx-translate/core";

class CustomerState {
  currentCustomer: CustomerModel;
  countryToCustomersMap: { [countryId: number]: Array<CustomerModel> } = {};
}

/**
 * Service for handling everything regarding customers
 */
@Injectable({ providedIn: "root" })
export class CustomerService extends BiStore<CustomerState> {
  private customersLastRefreshed: moment.Moment;
  private customersCacheExpirationHours = 2;

  public currentCustomerId: number;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    eventsManager: GlobalStateAndEventsService
  ) {
    super(new CustomerState());
    eventsManager.loginEvent.subscribe(newUser => {
      // make sure that customer is set before moving on! The all components needing current customer can
      // just get it from cache
      if (!this.state.value.currentCustomer || newUser.customerId !== this.state.value.currentCustomer.id) this.getCustomer().pipe(take(1)).subscribe();
    });

    eventsManager.customerChanged$.subscribe(_cId => this.getCustomer().pipe(take(1)).subscribe());
  }

  private _tilbudRevisionFilter = "Seneste";
  get wikiRevisionFilter(): string {
    return this._tilbudRevisionFilter;
  }
  set wikiRevisionFilter(value: string) {
    this._tilbudRevisionFilter = value;
  }

  /**
   * Get customer by either customer ID or the public GUID id. If no id is provided, current customer is returned.
   */
  public getCustomer(id?: number, publicId?: string): Observable<CustomerModel> {
    const params: { [key: string]: string } = {};
    if (id) params.id = id.toString();
    if (!isStringNullOrEmpty(publicId)) params.publicId = publicId;

    return this.http.get<CustomerModel>(ApiRoutes.customerRoutes.get.getCustomer, id ? { params: params } : undefined).pipe(
      tap(c => {
        if (id) return c;

        const stateClone = cloneObject(this.state.value);
        if (stateClone.countryToCustomersMap[c.countryId]) {
          const idx = stateClone.countryToCustomersMap[c.countryId].findIndex(cust => cust.id === c.id);
          if (idx !== -1) stateClone.countryToCustomersMap[c.countryId][idx] = c;
          else stateClone.countryToCustomersMap[c.countryId].push(c);
        } else stateClone.countryToCustomersMap[c.countryId] = [c];

        stateClone.currentCustomer = c;
        this.state.next(stateClone);
      })
    );
  }

  public sendMail(messageId: number) {
    const params: { [key: string]: string } = {};
    params.messageId = messageId.toString();

    return this.http.get(ApiRoutes.commonRoutes.sendMail, { params: params }).pipe(
      tap(c => {
        console.log("test");
      })
    );
  }

  public getEmails(fromDateUtc: Date, toDateUtc: Date, customerId?: number, categoryId?: number): Observable<EmailModel[]> {
    const params: { [key: string]: string } = {
      fromDateUtc: fromDateUtc.toDateString(),
      toDateUtc: toDateUtc.toDateString()
    };

    if (customerId) params["customerId"] = customerId.toString();
    if (categoryId) params["categoryId"] = categoryId.toString();

    return this.http
      .get<EmailModel[]>(ApiRoutes.commonRoutes.get.getEmails, {
        params: params
      })
      .pipe(
        map(emails => {
          return emails;
        })
      );
  }

  public getEmailAttachments(messageId: number): Observable<EmailAttachmentModel[]> {
    const params: { [key: string]: string } = {
      messageId: messageId.toString()
    };

    return this.http
      .get<EmailModel[]>(ApiRoutes.commonRoutes.get.getEmailAttachments, {
        params: params
      })
      .pipe(
        map(attachments => {
          return attachments;
        })
      );
  }

  public getEmailAttachmentById(id: number) {
    return this.http.get(ApiRoutes.commonRoutes.get.getEmailAttachmentById, {
      params: { id },
      responseType: "blob"
    });
  }

  public downloadEmailAttachmentById(id: number) {
    return this.http.get(ApiRoutes.commonRoutes.get.downloadEmailAttachmentById, {
      params: { id },
      responseType: "arraybuffer"
    });
  }

  public sendUnsentMails(): Observable<boolean> {
    return this.http.post<boolean>(ApiRoutes.customerRoutes.post.sendUnsentEmails, null, { params: {} });
  }

  // public getTavleEmails(tavleID?: number): Observable<EmailModel[]> {
  //   const params: { [key: string]: string } = {
  //     tavleID: tavleID.toString()
  //   };

  //   return this.http
  //     .get<EmailModel[]>(ApiRoutes.commonRoutes.get.getTavleEmails, {
  //       params: params
  //     })
  //     .pipe(
  //       map(emails => {
  //         return emails;
  //       })
  //     );
  // }

  public emailsByRefId(refId: number, refTypeId: number): Observable<EmailModel[]> {
    const params: { [key: string]: string } = {
      refId: refId.toString(),
      refTypeId: refTypeId.toString()
    };

    return this.http
      .get<EmailModel[]>(ApiRoutes.commonRoutes.get.emailsByRefId, {
        params: params
      })
      .pipe(
        map(emails => {
          return emails;
        })
      );
  }

  public initializeCustomerModel(): CustomerModel {
    // Return an initialized object
    return {
      id: 0,
      name: "",
      address: "",
      countryId: 1,
      timeZoneId: null,
      languageId: 1,
      companyRegistrationId: null
    };
  }

  public updateCustomer(customer: CustomerModel) {
    return this.http.post<number>(ApiRoutes.customerRoutes.post.updateCustomer, customer).pipe(
      catchError(err => {
        throw err;
      }),
      tap(cId => {
        customer.id = cId;
        const stateClone = cloneObject(this.getCurrentStateValue());
        // Update the local customer object in store if it exists
        if (stateClone.countryToCustomersMap[customer.countryId]) {
          const indexOfLocalCust = stateClone.countryToCustomersMap[customer.countryId].findIndex(c => c.id === customer.id);
          const customerClone = stateClone.countryToCustomersMap[customer.countryId][indexOfLocalCust];
          stateClone.countryToCustomersMap[customer.countryId][indexOfLocalCust] = { ...customerClone, ...customer };
        }

        // Was this customer the current one? Then this also needs an update
        if (stateClone.currentCustomer.id === customer.id)
          stateClone.currentCustomer = {
            ...stateClone.currentCustomer,
            ...customer
          };
        this.state.next(stateClone);
      })
    );
  }

  /**
   * Returns a list of all the users that current customer has
   */
  public getCustomerUsers(): Observable<UserEditModel[]> {
    return this.http.get<UserEditModel[]>(ApiRoutes.customerRoutes.get.getCustomerUsers).pipe(
      map((users: UserEditModel[]) => {
        return users.map(user => {
          // server serializes dates as strings so convert to what we want - moment js objects!
          // user.lastLoginUtc = moment(user.lastLoginUtc);
          // user.dateCreatedUtc = moment(user.dateCreatedUtc);
          return user;
        });
      })
    );
  }

  /**
   * Does the customer has any profiles with 1 or more of the specified profile roles?
   * @param roleNames Comma separated list of profile role names
   * @param customerId Optional customerId. If not provided, current customer's id is used
   * @returns Observable<boolean>
   */
  public customerHasAtleast1ProfileWith1OfRoles(roleNames: string, customerId?: number) {
    return this.http.get<boolean>(ApiRoutes.customerRoutes.get.customerHasAnyProfileWithRoles, {
      params: {
        customerId: customerId ? customerId.toString() : this.state.value.currentCustomer.id.toString(),
        roleNames: roleNames
      }
    });
  }

  //#region SUPER ADMIN
  /**
   * Returns all existing customers for a country. If local state already has these customers, a clone of these is
   * returned without making http call.
   */
  public getCustomers(countryId?: BiCountryId, onlyDeleted = false): Observable<CustomerModel[]> {
    // Check if we have local state that hasn't expired and return that
    // if (
    //   !inclDeleted &&
    //   this.customersLastRefreshed &&
    //   this.customersLastRefreshed.diff(moment(), "hours") <
    //   this.customersCacheExpirationHours
    // ) {
    //   // At the beginning, the "currentCustomer" could be the only customer in a country - meaning that all customers hasn't been fetched before
    //   if (
    //     this.state.value.countryToCustomersMap[countryId] &&
    //     this.state.value.countryToCustomersMap[countryId].length > 1
    //   ) {
    //     this.state.next({ ...this.state.value });
    //     return of(
    //       cloneObject(this.state.value.countryToCustomersMap[countryId])
    //     );
    //   }
    // }
    //console.log("calling: ApiRoutes.customerRoutes.get.getCustomers")

    //console.log("calling: ApiRoutes.customerRoutes.get.getCustomers", countryId, onlyDeleted)

    let params: { [key: string]: string };
    if (countryId)
      params = {
        countryId: countryId.toString(),
        onlyDeleted: onlyDeleted ? "true" : "false"
      };
    return this.http
      .get<CustomerModel[]>(ApiRoutes.customerRoutes.get.getCustomers, {
        params: params
      })
      .pipe(
        map(customers => {
          const map = this.state.value.countryToCustomersMap;
          map[countryId] = customers;
          this.customersLastRefreshed = moment();
          this.state.next({ ...this.state.value, countryToCustomersMap: map });
          return cloneObject(customers);
        })
      );
  }

  //#region Various stuff.
  /**
   * Returns a list of all possible user roles along with a flag telling whether users will become the role or not when created.dataTable
   * @param onlyHasAccess Set true if only the user roles that the customer's user has access to should be returned
   */
  public getCustomerUserRoleAccess(customerId: number, onlyHasAccess?: boolean) {
    const params: { [key: string]: string } = {
      customerId: customerId.toString()
    };
    if (onlyHasAccess) params.onlyHasAccess = "true";

    return this.http.get<Array<CustomerUserRole>>(ApiRoutes.customerRoutes.get.getCustomerUserRoleAccess, { params: params });
  }

  public getAllLocalizedResources(): Observable<LocaleStringResource[]> {
    return this.http.get<Array<LocaleStringResource>>(ApiRoutes.commonRoutes.localizationRoutes.getAllResourcesForEdit, {});
  }

  /**
   * Inserts/creates a new localized resource
   * @param languageId 1 for "DK", 2 for "SV"
   */
  public insertLocalizedResources(resource: LocaleStringResource): Observable<any> {
    const params = {
      resourceName: resource.resourceName,
      resourceValue: resource.resourceValue,
      languageId: resource.languageId.toString()
    };
    return this.http.post(ApiRoutes.commonRoutes.localizationRoutes.insertLocalizedResource, null, { params: params }).pipe(
      tap(() => {
        // Update frontend cache of translations so the new translation will display
        const newTransObject = this.createTranslationObjectForUpdate(resource.resourceName, resource.resourceValue);
        // (language id must be treated as a string to handle when its value comes from a dropdown select)
        //this.translator.setTranslation(languageId.toString() === "1" ? "da" : "se", newTransObject, true);
        this.translate.setTranslation(getLanguageCodeByLanguageId(resource.languageId), newTransObject, true);
      })
    );
  }

  /**
   * Updates a specific resource
   * @param languageId 1 for "DK", 2 for "SV"
   */
  public updateLocalizedResource(id: number, resourceName: string, resourceValue: string, languageId: number): Observable<any> {
    if (id) {
      const params = {
        id: id.toString(),
        resourceName: resourceName,
        resourceValue: resourceValue,
        languageId: languageId.toString()
      };
      return this.http.post(ApiRoutes.commonRoutes.localizationRoutes.updateLocalizedResource, null, { params: params }).pipe(
        tap(() => {
          // Update the value of the tranlation in frontend to reflect the changes
          const newTransObject = this.createTranslationObjectForUpdate(resourceName, resourceValue);
          // (language id must be treated as a string to handle when its value comes from a dropdown select)
          this.translate.setTranslation(languageId.toString() === "1" ? "da" : "se", newTransObject, true);
        })
      );
    } else return observableThrowError("No id provided!");
  }

  /**
   * Deletes a resource
   */
  public deleteLocalizedResource(id: number): Observable<any> {
    return this.http.post(ApiRoutes.commonRoutes.localizationRoutes.deleteLocalizedResource, null, {
      params: { id: id.toString() }
    });
  }

  /**
   * Helper function for creating an object to be used by the setTranslation() function of Ngx Translate. This library has a bug in the "set()" function causing no handling of
   * nested translation keys. Therefore, we must manually create a nested object from the translation key (if necessary). By calling setTranslation() with the object returned from this function,
   * the translation will be updated in frontend cache and new value will be displayed instantly!
   * @param transKey Translation key
   * @param transValue The translated value
   */
  private createTranslationObjectForUpdate(transKey, transValue) {
    const translations = {};
    setObjectValueByString(translations, transKey, transValue);
    return translations;
  }

  public getWikiDocuments(revisionType: string): Observable<WikiDocumentDto[]> {
    const params: { [key: string]: string } = { revisionType: revisionType };

    return this.http
      .get<WikiDocumentDto[]>(ApiRoutes.commonRoutes.get.getWikiDocuments, {
        params: params
      })
      .pipe(
        map(emails => {
          return emails;
        })
      );
  }

  public saveWikiDocument(dto: WikiDocumentDto): Observable<any> {
    return this.http.post<WikiDocumentDto>(ApiRoutes.commonRoutes.post.saveWikiDocument, dto);
  }

  public getFarms(customerId: number): Observable<FarmDto[]> {
    const params: { [key: string]: string } = { customerId: customerId.toString() };

    return this.http
      .get<FarmDto[]>(ApiRoutes.customerRoutes.get.getFarms, {
        params: params
      })
      .pipe(
        map(farms => {
          return farms;
        })
      );
  }

  public saveFarm(dto: FarmDto): Observable<any> {
    return this.http.post<FarmDto>(ApiRoutes.customerRoutes.post.saveFarm, dto);
  }
}
