export enum RefType {
  Helpdesk = 1,
  ModulDokument = 2,
  BrugerDevice = 3,
  Treatment = 4,
  Overvaagning = 5,
  Bruger = 7,
  Certification = 8,
  TreatmentCourses = 9,
  Prospect = 10,
  Customer = 11
}
