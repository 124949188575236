<form *ngIf="mainForm && selectedCompany" [formGroup]="mainForm">
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <!-- <h3 class="card-header">Data fra CHR</h3> -->
        <h3>{{ selectedCompany?.brugerNavn }}</h3>
        <div class="p-fluid p-formgrid grid">
          <div class="field col-12 md:col-2">
            <label for="chrNr" class="block bold-label">Chr nummer</label>
            <p id="chrNr">{{ selectedCompany?.chrNr }}</p>
          </div>

          <div *ngIf="selectedCompany?.chrNr != selectedCompany?.besNr" class="field col-12 md:col-2">
            <label for="chrNr" class="block bold-label">Besætnings nr</label>
            <p id="chrNr">{{ selectedCompany?.besNr }}</p>
          </div>

          <!-- <div class="field col-12 md:col-2">
            <label for="brugerNavn" class="block bold-label">Navn</label>
            <p id="ejerNavn">{{ selectedCompany?.brugerNavn }}</p>
          </div> -->

          <div *ngIf="selectedCompany?.brugerNavn != selectedCompany?.ejerNavn" class="field col-12 md:col-2">
            <label for="ejerNavn" class="block bold-label">Ejer</label>
            <p id="ejerNavn">{{ selectedCompany?.ejerNavn }}</p>
          </div>

          <div class="field col-12 md:col-2">
            <label for="adresse" class="block bold-label">Adresse</label>
            <p id="adresse">{{ selectedCompany?.adresse }}</p>
          </div>

          <div class="field col-12 md:col-2">
            <label for="postNummer" class="block bold-label">Post Nr</label>
            <p id="postNummer">{{ selectedCompany?.postNummer }}</p>
          </div>

          <div class="field col-12 md:col-2">
            <label for="postDistrikt" class="block bold-label">Postdistrikt</label>
            <p id="postDistrikt">{{ selectedCompany?.postDistrikt }}</p>
          </div>

          <div class="field col-12 md:col-2">
            <label for="region" class="block bold-label">Region</label>
            <p id="region">{{ selectedCompany?.region }}</p>
          </div>

          <div class="field col-12 md:col-2">
            <label for="cvrNummer" class="block bold-label">Cvr nummer</label>
            <p id="cvrNummer">{{ selectedCompany?.cvrNummer }}</p>
          </div>

          <div class="field col-12 md:col-2">
            <label for="koer" class="block bold-label">Besætningstype</label>
            <p id="koer">{{ selectedCompany?.besaetningstype }}</p>
          </div>

          <div class="field col-12 md:col-2">
            <label for="sDSygdomsNiveauKode" class="block bold-label">Salmonella dublin</label>
            <p id="handyr">{{ selectedCompany?.sdSygdomsNiveauKode }}</p>
          </div>

          <div class="field col-12 md:col-2">
            <label for="koer" class="block bold-label">Køer</label>
            <p id="koer">{{ selectedCompany?.koer }}</p>
          </div>
          <div class="field col-12 md:col-2">
            <label for="kvier" class="block bold-label">Kvier</label>
            <p id="kvier">{{ selectedCompany?.kvier }}</p>
          </div>
          <div class="field col-12 md:col-2">
            <label for="handyr" class="block bold-label">Handyr</label>
            <p id="handyr">{{ selectedCompany?.handyr }}</p>
          </div>

          <div *ngIf="selectedCompany?.cvrData" class="field col-12 md:col-4">
            <label for="cvrData" class="block bold-label">Kontaktoplysninger fra CVR</label>
            <p id="cvrData">{{ selectedCompany?.cvrData }}</p>
          </div>

          <div *ngIf="selectedCompany?.adresseTelefonData" class="field col-12 md:col-4">
            <label for="cvrData" class="block bold-label">Telefonoplysninger fra adressen</label>
            <p id="adresseTelefonData">{{ selectedCompany?.adresseTelefonData }}</p>
          </div>

          <div *ngIf="selectedCompany?.ejerTelefonData" class="field col-12 md:col-4">
            <label for="ejerTelefonData" class="block bold-label"> Telefonoplysninger fra ejer adressen</label>
            <p id="ejerTelefonData">{{ selectedCompany?.ejerTelefonData }}</p>
          </div>

          <!-- <div class="field col-12 md:col-12">
            <label for="cvrData" class="block bold-label">Teledata</label>
            <p *ngIf="selectedCompany?.cvrData" id="handyr">cvrData<br />{{ selectedCompany?.cvrData }}</p>
            <p *ngIf="selectedCompany?.adresseTelefonData" id="adresseTelefonData">Telefondata adresse<br />{{ selectedCompany?.adresseTelefonData }}</p>
            <p *ngIf="selectedCompany?.ejerTelefonData" id="handyr">Telefondata ejer adresse<br />{{ selectedCompany?.ejerTelefonData }}</p>
          </div> -->
        </div>
      </div>

      <div class="card">
        <!-- <h3 class="card-header">Oplysninger</h3> -->
        <div class="p-fluid p-formgrid grid">
          <!-- ansvarligBrugerId -->
          <div class="field col-12 md:col-3">
            <label for="ansvarligBrugerId" class="block bold-label">Prospect ansvarlig</label>
            <p-dropdown
              formControlName="ansvarligBrugerId"
              optionLabel="label"
              dataKey="value"
              [options]="userItems"
              [(ngModel)]="farmUserItem"
              scrollHeight="400px"
              [style]="{ 'min-width': '12rem' }"
              id="ansvarligBrugerId"
              aria-describedby="ansvarligBrugerId-help"
              [scrollHeight]="'auto'"
            ></p-dropdown>
            <div *ngIf="ansvarligBrugerId.errors && (ansvarligBrugerId.dirty || ansvarligBrugerId.touched)" class="p-error block">
              <div *ngIf="ansvarligBrugerId.errors?.required">Påkrævet</div>
              <div *ngIf="ansvarligBrugerId.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <!-- contactName -->
          <div class="field col-12 md:col-3">
            <label for="contactName" class="block bold-label">Kontakt navn 1</label>
            <input formControlName="contactName" type="text" pInputText id="contactName" aria-describedby="contactName-help" autocomplete="one-time-code" />
            <div *ngIf="contactName.errors && (contactName.dirty || contactName.touched)" class="p-error block">
              <div *ngIf="contactName.errors?.required">Påkrævet</div>
              <div *ngIf="contactName.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>
          <!-- contactPhone -->
          <div class="field col-12 md:col-3">
            <label for="contactPhone" class="block bold-label">Kontakt telefon 1</label>
            <input formControlName="contactPhone" type="text" pInputText id="contactPhone" aria-describedby="contactPhone-help" autocomplete="one-time-code" />
            <div *ngIf="contactPhone.errors && (contactPhone.dirty || contactPhone.touched)" class="p-error block">
              <div *ngIf="contactPhone.errors?.required">Påkrævet</div>
              <div *ngIf="contactPhone.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>
          <!-- contactEmail -->
          <div class="field col-12 md:col-3">
            <label for="contactEmail" class="block bold-label">Kontakt email 1</label>
            <input formControlName="contactEmail" type="email" pInputText id="contactEmail" aria-describedby="contactEmail-help" autocomplete="one-time-code" />
            <div *ngIf="contactEmail.errors && (contactEmail.dirty || contactEmail.touched)" class="p-error block">
              <div *ngIf="contactEmail.errors?.email && contactEmail.touched">Invalid email</div>
              <div *ngIf="contactEmail.errors?.required">Påkrævet</div>
              <div *ngIf="contactEmail.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <!-- status -->
          <div class="field col-12 md:col-3">
            <label for="status" class="block bold-label">Prospect status</label>
            <p-dropdown
              formControlName="status"
              optionLabel="label"
              dataKey="value"
              [options]="statusFilterItems"
              [(ngModel)]="farmLeadStatusItem"
              scrollHeight="400px"
              [style]="{ 'min-width': '12rem' }"
              id="status"
              aria-describedby="status-help"
            ></p-dropdown>
            <div *ngIf="status.errors && (status.dirty || status.touched)" class="p-error block">
              <div *ngIf="status.errors?.required">Påkrævet</div>
              <div *ngIf="status.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <!-- contactName1 -->
          <div class="field col-12 md:col-3">
            <label for="contactName1" class="block bold-label">Kontakt navn 2</label>
            <input formControlName="contactName1" type="text" pInputText id="contactName1" aria-describedby="contactName1-help" autocomplete="one-time-code" />
            <div *ngIf="contactName1.errors && (contactName1.dirty || contactName1.touched)" class="p-error block">
              <div *ngIf="contactName1.errors?.required">Påkrævet</div>
              <div *ngIf="contactName1.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <!-- contactPhone1 -->
          <div class="field col-12 md:col-3">
            <label for="contactPhone1" class="block bold-label">Kontakt telefon 2</label>
            <input formControlName="contactPhone1" type="text" pInputText id="contactPhone1" aria-describedby="contactPhone1-help" autocomplete="one-time-code" />
            <div *ngIf="contactPhone1.errors && (contactPhone1.dirty || contactPhone1.touched)" class="p-error block">
              <div *ngIf="contactPhone1.errors?.required">Påkrævet</div>
              <div *ngIf="contactPhone1.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <!-- contactEmail1 -->
          <div class="field col-12 md:col-3">
            <label for="contactEmail1" class="block bold-label">Kontakt email 2</label>
            <input formControlName="contactEmail1" type="email" pInputText id="contactEmail1" aria-describedby="contactEmail1-help" autocomplete="one-time-code" />
            <div *ngIf="contactEmail1.errors && (contactEmail1.dirty || contactEmail1.touched)" class="p-error block">
              <div *ngIf="contactEmail1.errors?.email && contactEmail1.touched">Invalid email</div>
              <div *ngIf="contactEmail1.errors?.required">Påkrævet</div>
              <div *ngIf="contactEmail1.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <!-- mejerier -->
          <div class="field col-12 md:col-3">
            <label for="mejerier" class="block bold-label">Mejeri</label>
            <p-dropdown
              formControlName="mejerier"
              optionLabel="label"
              dataKey="value"
              [options]="mejeriItems"
              [(ngModel)]="farmMejeriItem"
              scrollHeight="400px"
              [style]="{ 'min-width': '12rem' }"
              placeholder="Vælg et mejeri"
              id="mejerier"
            ></p-dropdown>
            <div *ngIf="mejerier.errors && (mejerier.dirty || mejerier.touched)" class="p-error block">
              <div *ngIf="mejerier.errors?.required">Påkrævet</div>
            </div>
          </div>

          <!-- malkesystem -->
          <div class="field col-12 md:col-3">
            <label for="malkesystem" class="block bold-label">Malkesystem</label>
            <p-dropdown
              formControlName="malkesystem"
              optionLabel="label"
              dataKey="value"
              [options]="malkesystemItems"
              [(ngModel)]="farmMalkesystemItem"
              scrollHeight="400px"
              [style]="{ 'min-width': '12rem' }"
              placeholder="Vælg et malkesystem"
              id="malkesystem"
            ></p-dropdown>

            <!-- Validerings-fejl -->
            <div *ngIf="malkesystem.errors && (malkesystem.dirty || malkesystem.touched)" class="p-error block">
              <div *ngIf="malkesystem.errors?.required">Påkrævet</div>
            </div>
          </div>

          <div class="field col-12 md:col-3">
            <label for="race" class="block bold-label">Race</label>
            <input formControlName="race" type="text" pInputText id="race" aria-describedby="race-help" />
            <div *ngIf="race.errors && (race.dirty || race.touched)" class="p-error block">
              <div *ngIf="race.errors?.required">Påkrævet</div>
              <div *ngIf="race.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-12">
            <label for="noter" class="block bold-label">Generelle prospect noter</label>
            <textarea id="noter" formControlName="noter" type="text" aria-describedby="noter-help" placeholder="Angiv prospect noter" [rows]="3" pInputTextarea autoResize="autoResize"></textarea>
            <div *ngIf="noter.errors && (noter.dirty || noter.touched)" class="p-error block"></div>
          </div>

          <div class="field col-12">
            <div class="formgroup-inline">
              <div class="field">
                <button
                  pButton
                  pRipple
                  type="button"
                  [label]="'Gem og luk'"
                  class="p-button-raised"
                  [title]="mainForm.valid ? 'Gem dine indtastede data' : 'Deaktiveret, indtil formulardataene er gyldige'"
                  [disabled]="mainForm.pristine"
                  (click)="saveItem()"
                ></button>
              </div>
              <div class="field">
                <button
                  pButton
                  pRipple
                  type="button"
                  [label]="'Gem'"
                  class="p-button-raised"
                  [title]="mainForm.valid ? 'Gem dine indtastede data' : 'Deaktiveret, indtil formulardataene er gyldige'"
                  [disabled]="mainForm.pristine"
                  (click)="saveItem(false)"
                ></button>
              </div>
              <div class="field">
                <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Annuller" title="Annuller" [disabled]="mainForm.pristine" (click)="close(null)"></button>
              </div>

              <!-- <div class="field">
                <p-splitButton label="Aktioner" icon="pi pi-cog" [model]="commands"></p-splitButton>
              </div> -->

              <div class="field">
                <button
                  pButton
                  pRipple
                  type="button"
                  [label]="'Opret som kunde'"
                  class="p-button-raised"
                  [title]="'Opret som kunde'"
                  [disabled]="mainForm.dirty || selectedCompany?.customerId"
                  (click)="confirmCreateCustomer()"
                ></button>
              </div>

              <div class="field">
                <button
                  [disabled]="
                    mainForm.dirty || selectedCompany?.contactEmail === null || selectedCompany?.contactEmail === '' || selectedCompany?.contactName === null || selectedCompany?.contactName === ''
                  "
                  pButton
                  pRipple
                  type="button"
                  [label]="'Opret salgs e-mail' | translate"
                  class="p-button-raised"
                  [title]="'Opret salgs e-mail'"
                  (click)="createTrialPeriodIntroMail()"
                ></button>
              </div>
              <!-- <div class="field">
                <a
                  href="https://app-eu1.hubspot.com/contacts/144491104/company/{{ selectedCompany.prospectId }}"
                  (click)="openInNamedWindow($event, 'HubspotWindow')"
                  target="HubspotWindow"
                  class="hubspot-link"
                  >Åbn i Hubspot</a
                >
              </div> -->
              <!-- <div class="field"><button class="ml-5 mr-2" [disabled]="userId !== 25" pButton type="button" [label]="'Opret Email' | translate" (click)="createMail()"></button></div> -->
              <!--
              <div class="field"><button class="ml-5" [disabled]="userId !== 25" pButton type="button" [label]="'Opret aftale' | translate" (click)="createAppointment()"></button></div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <h3>Prøveperiode: {{ selectedCompany?.brugerNavn }}</h3>
        <div class="p-fluid p-formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="trialPeriodUserIds" class="block bold-label">Instruktører</label>
            <p-multiSelect
              formControlName="trialPeriodUserIds"
              [options]="userItemsTrialPeriod"
              dataKey="value"
              optionLabel="label"
              defaultLabel="Vælg Instruktører"
              [scrollHeight]="'auto'"
            ></p-multiSelect>
          </div>

          <div class="field col-12 md:col-2">
            <label for="trialPeriodStatus" class="block bold-label">Status</label>
            <p-dropdown formControlName="trialPeriodStatus" [options]="trialPeriodStatusItems" dataKey="value" optionLabel="label" [scrollHeight]="'auto'">
              <ng-template let-item pTemplate="item">
                <div class="dropdown-item" [style.background-color]="item.title">
                  {{ item.label }}
                </div>
              </ng-template>
              <ng-template let-item pTemplate="selectedItem">
                <div class="dropdown-selected-item" [style.background-color]="item.title">
                  {{ item.label }}
                </div>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="field col-12 md:col-2">
            <label for="trialPeriodStartWeekYearId" class="block bold-label">Start uge</label>
            <p-dropdown
              formControlName="trialPeriodStartWeekYearId"
              optionLabel="label"
              dataKey="value"
              [options]="weekYearItems"
              [(ngModel)]="trialPeriodStartWeekItem"
              scrollHeight="400px"
              [style]="{ 'min-width': '12rem' }"
              placeholder="Vælg et trialPeriodStartWeekYearId"
              id="trialPeriodStartWeekYearId"
            ></p-dropdown>
          </div>

          <div class="field col-12 md:col-2">
            <label for="TrialPeriodStartDayDropdown" class="block bold-label">Teori dag</label>
            <p-dropdown
              formControlName="trialPeriodStartDay"
              optionLabel="label"
              dataKey="value"
              [(ngModel)]="trialPeriodStartDayItem"
              [options]="trialPeriodStartDayItems"
              placeholder="Vælg start dag"
              id="trialPeriodStartDay"
              [scrollHeight]="'auto'"
            >
            </p-dropdown>
          </div>

          <div class="field col-12 md:col-2">
            <label for="TrialPeriodStartHourDropdown" class="block bold-label">Start time</label>
            <p-dropdown
              formControlName="trialPeriodStartHour"
              optionLabel="label"
              dataKey="value"
              [(ngModel)]="trialPeriodStartHourItem"
              [options]="trialPeriodStartHourItems"
              placeholder="Vælg time"
              id="trialPeriodStartHour"
            >
            </p-dropdown>
          </div>

          <div class="field col-12 md:col-2">
            <label for="trialPeriodPersons" class="block bold-label">Antal pers</label>
            <input formControlName="trialPeriodPersons" type="text" maxlength="100" pInputText id="trialPeriodPersons" aria-describedby="trialPeriodPersons-help" autocomplete="one-time-code" />
            <div *ngIf="trialPeriodPersons.errors && (contactName.dirty || contactName.touched)" class="p-error block">
              <div *ngIf="contactName.errors?.digitsOnly">Kun hel tal</div>
            </div>
          </div>

          <div class="field col-12 md:col-2">
            <label for="trialPeriodLanguage" class="block bold-label">Sprog</label>
            <input formControlName="trialPeriodLanguage" type="text" maxlength="100" pInputText id="trialPeriodLanguage" aria-describedby="trialPeriodLanguage-help" autocomplete="one-time-code" />
          </div>

          <div class="field col-12 md:col-2">
            <label for="trialPeriodStartWeekYearId" class="block bold-label">Detailplan{{ mainForm.dirty ? " (gem først)" : "" }}</label>
            <button
              [disabled]="
                trialPeriodDialogVisible ||
                mainForm.dirty ||
                !selectedCompany?.trialPeriodStartWeekYearId ||
                !selectedCompany?.trialPeriodStartDay ||
                !selectedCompany?.trialPeriodStartHour ||
                selectedCompany?.trialPeriodUserIds === ''
              "
              pButton
              pRipple
              type="button"
              [label]="selectedCompany?.trialPeriodDaysCreated === true ? 'Rediger' : 'Opret'"
              class="p-button-raised"
              [title]="'Rediger detaljer'"
              (click)="trialPeriodDialogVisible = true"
            ></button>
          </div>

          <div class="field col-12 md:col-2">
            <label for="trialPeriodStartWeekYearId" class="block bold-label">Prøveperiode e-mail{{ mainForm.dirty ? " (gem først)" : "" }}</label>
            <button
              [disabled]="
                selectedCompany?.trialPeriodDaysCreated !== true ||
                mainForm.dirty ||
                selectedCompany?.contactEmail === null ||
                selectedCompany?.contactEmail === '' ||
                selectedCompany?.contactName === null ||
                selectedCompany?.contactName === '' ||
                selectedCompany?.trialPeriodUserIds === ''
              "
              pButton
              pRipple
              type="button"
              [label]="'Opret e-mail' | translate"
              class="p-button-raised"
              [title]="'Opret e-mail'"
              (click)="createTrialPeriodScheduleMail()"
            ></button>
          </div>

          <!-- <div *ngIf="selectedCompany?.trialPeriodDaysCreated && !selectedCompany?.trialPeriodCreateInCalender" class="field col-12 md:col-2">
            <label for="trialPeriodStartWeekYearId" class="block bold-label">Opret som kaleder aftaler</label>
            <button [disabled]="mainForm.dirty" pButton pRipple type="button" [label]="'Opret' | translate" class="p-button-raised" [title]="'Opret'" (click)="createTrialPeriodInCalender()"></button>
          </div>

          <div *ngIf="selectedCompany?.trialPeriodDaysCreated && selectedCompany?.trialPeriodCreateInCalender" class="field col-12 md:col-2">
            <label for="trialPeriodStartWeekYearId" class="block bold-label">Opret som kaleder aftaler</label>
            <button [disabled]="mainForm.dirty" pButton pRipple type="button" [label]="'Fjern' | translate" class="p-button-raised" [title]="'Fjern'" (click)="removeTrialPeriodInCalender()"></button>
          </div> -->

          <div class="field col-12 md:col-2">
            <label for="trialPeriodHideInCalender" class="block bold-label">Skjul i outlook</label>
            <p-checkbox [inputId]="'trialPeriodHideInCalender'" formControlName="trialPeriodHideInCalender" [label]="'Skjul i kalender'" binary="true"></p-checkbox>
          </div>

          <div class="field col-12 md:col-6">
            <label for="trialPeriodNotes" class="block bold-label">Prøveperiode noter(kommer med i mails/kalender)</label>
            <textarea
              id="trialPeriodNotes"
              formControlName="trialPeriodNotes"
              type="text"
              aria-describedby="trialPeriodNotes-help"
              placeholder="Angiv prøveperiode noter - kommer med i mails/kalender"
              [rows]="3"
              pInputTextarea
              autoResize="autoResize"
            ></textarea>
          </div>

          <div class="field col-12 md:col-6">
            <label for="trialPeriodNotesInternal" class="block bold-label">INTERNE noter(kommer kun med i kalender)</label>
            <textarea
              id="trialPeriodNotesInternal"
              formControlName="trialPeriodNotesInternal"
              type="text"
              aria-describedby="trialPeriodNotesInternal-help"
              placeholder="Angiv INTERNE Prøveperiode noter - kommer med i kalenderen"
              [rows]="3"
              pInputTextarea
              autoResize="autoResize"
              class="internal-notes-textarea"
            ></textarea>
          </div>
        </div>
        <app-prospect-trialperiod-scheduler (prospectIdSelected)="onNewProspectIdSelected($event)"></app-prospect-trialperiod-scheduler>
      </div>
    </div>
  </div>
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" [style]="{ width: '50vw' }" [baseZIndex]="10000"></p-confirmDialog>
  <p-toast></p-toast>
  <div class="alert alert-danger" *ngIf="errorMessage">{{ errorMessage }}</div>
</form>

<p-dialog
  *ngIf="trialPeriodDialogVisible"
  [header]="'Rediger prøve periode'"
  [(visible)]="trialPeriodDialogVisible"
  [modal]="true"
  (onHide)="handleTrialPeriodDialogCloseDialog(false)"
  [style]="{ width: '75vw', minWidth: '1200px', minHeight: '80vh' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <!-- <app-trail-period-detail *ngIf="trialPeriodDialogVisible" [TrialPeriodId]="selectedCompany.id" (closeDialog)="handleTrialPeriodDialogCloseDialog($event)"></app-trail-period-detail> -->

  <app-trial-period-calendar [trialPeriodId]="selectedCompany.id" (closeDialog)="handleTrialPeriodDialogCloseDialog($event)"></app-trial-period-calendar>
</p-dialog>

<!-- <p-dialog
  *ngIf="calendarDialogVisible"
  [header]="'Rediger farm' | translate"
  [(visible)]="calendarDialogVisible"
  [modal]="true"
  [style]="{ width: '95vw', minHeight: '90vh' }"
  [maximizable]="true"
  (onHide)="calendarDialogVisible = false"
>
  <app-trial-period-calendar [TrialPeriodId]="2983" [trialPeriodDays]="sortedTrialPeriodDays" (closeDialog)="handleCloseDialog($event)"></app-trial-period-calendar>
</p-dialog> -->
