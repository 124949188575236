<ejs-schedule
  #scheduleObj
  width="100%"
  height="100%"
  [selectedDate]="currentDate"
  [currentView]="'Month'"
  [eventSettings]="eventSettings"
  [firstDayOfWeek]="1"
  timeFormat="HH:mm"
  rowAutoHeight="true"
  (eventRendered)="onEventRendered($event)"
  (actionComplete)="onActionComplete($event)"
  (eventClick)="!selectedItem && onEventClick($event)"
  (cellClick)="!selectedItem && addNewActivity($event)"
  (cellDoubleClick)="!selectedItem && addNewActivity($event)"
  (created)="onCreate()"
  (navigating)="onNavigating($event)"
  (moreEventsClick)="!selectedItem && onMoreEventsClick($event)"
>
  <ng-template #eventTemplate let-data>
    <div class="event-template">
      <div class="subject">
        {{ data.Subject }}
        {{ data.EndTimeDK }}
        {{ data.Employees.join(", ") }}
        {{ data.Description ? "*" : "" }}
      </div>
    </div>
  </ng-template>

  <e-views>
    <!-- <e-view option="Week" startHour="06:00" endHour="24:00"></e-view> -->
    <e-view option="Month" [showWeekNumber]="true" numberOfWeeks="7" [showWeekend]="showWeekend"></e-view>
  </e-views>
</ejs-schedule>

<div class="field col-12">
  <div class="formgroup-inline">
    <div class="field">
      <button pButton label="Gem og luk" (click)="onSave()" class="p-button-success"></button>
    </div>

    <div class="field">
      <button pButton pRipple type="button" class="p-button-outlined p-button-secondary" label="Luk" title="Luk" (click)="close(false)"></button>
    </div>

    <div class="field">
      <button pButton type="button" label="Fjern tomme dage" (click)="removeEmptyGaps()"></button>
    </div>

    <!-- <div class="field" *ngIf="onServer()">
      <button pButton type="button" label="Nulstill" (click)="reset()"></button>
    </div> -->

    <div class="field">
      <label for="typeId">Indsæt aktivitet</label>
      <p-dropdown [options]="lessonTypes" [(ngModel)]="selectedLessonType" optionLabel="name" optionValue="id" placeholder="Vælg type"></p-dropdown>
      <button pButton type="button" label="Indsæt" (click)="addAppointment()"></button>
    </div>
  </div>
</div>

<div class="grid" *ngIf="selectedItem">
  <div class="col-12">
    <div class="card">
      <h3 class="card-header">Rediger</h3>
      <div class="p-fluid p-formgrid grid">
        <div class="field col-12 md:col-3">
          <label for="typeId">Type</label>
          <p-dropdown id="typeId" [options]="lessonTypes" [(ngModel)]="selectedItem.typeId" optionLabel="name" optionValue="id" placeholder="Vælg type"> </p-dropdown>
        </div>

        <div class="field col-12 md:col-3">
          <label for="userIds">Brugere</label>
          <p-multiSelect id="userIds" [options]="userItems" [(ngModel)]="selectedUserItems" dataKey="value" optionLabel="label" placeholder="Vælg brugere"> </p-multiSelect>
        </div>
        <div class="field col-12 md:col-3">
          <label for="plannedStartTime" class="bold-label">Start Tid</label>
          <p-dropdown id="plannedStartTime" [(ngModel)]="selectedItem.plannedStartTime" [options]="timeIntervals" placeholder="Vælg starttidspunkt"> </p-dropdown>
        </div>
        <div class="field col-12 md:col-3">
          <label for="plannedEndTime" class="bold-label">Slut Tid (ikke med i mail)</label>
          <p-dropdown id="plannedEndTime" [(ngModel)]="selectedItem.plannedEndTime" [options]="timeIntervals" placeholder="Vælg sluttidspunkt"> </p-dropdown>
        </div>
        <div class="field col-12 md:col-12">
          <label for="notes">Noter</label>
          <textarea id="notes" rows="3" [(ngModel)]="selectedItem.notes" placeholder="Tilføj noter" class="p-inputtextarea"> </textarea>
        </div>
        <div class="field col-12 text-center">
          <div class="formgroup-inline">
            <div class="field">
              <button pButton type="button" label="Ok" icon="pi pi-check" (click)="handleSaveDialog()" class="p-button-success"></button>
            </div>
            <div class="field">
              <button pButton type="button" label="Annuller" icon="pi pi-times" (click)="handleCloseDialog()" class="p-button-secondary"></button>
            </div>
            <div class="field">
              <button pButton type="button" label="Slet" icon="pi pi-trash" (click)="handleDeleteDialog()" class="p-button-danger"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
