import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BenefitCalculationDto } from "@apiModels/benefitCalculationDto";
import { ProspectDto } from "@apiModels/prospectDto";
import { ProspectMapDto } from "@apiModels/prospectMapDto";
import { ProspectModel } from "@apiModels/prospectModel";
import { ProspectNoteDto } from "@apiModels/prospectNoteDto";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class HubspotService {
  constructor(private http: HttpClient) {}

  public getProspectsByTeam(team: string, fraKoer?: number, tilKoer?: number): Observable<ProspectMapDto[]> {
    const params: { [key: string]: string } = {
      team: team,
      fraKoer: fraKoer?.toString(),
      tilKoer: tilKoer?.toString()
    };

    return this.http.get<Array<ProspectMapDto>>(ApiRoutes.hubspotRoutes.get.getProspectsByTeam, { params: params });
  }

  public getProspectsByRegion(region: string, fraKoer?: number, tilKoer?: number): Observable<ProspectMapDto[]> {
    const params: { [key: string]: string } = {
      region: region,
      fraKoer: fraKoer?.toString(),
      tilKoer: tilKoer?.toString()
    };

    return this.http.get<Array<ProspectMapDto>>(ApiRoutes.hubspotRoutes.get.getProspectsByRegion, { params: params });
  }

  public getProspectsForMap(): Observable<ProspectMapDto[]> {
    const params: { [key: string]: string } = {};

    return this.http.get<Array<ProspectMapDto>>(ApiRoutes.hubspotRoutes.get.getProspectsForMap, { params: params });
  }

  public getProspects(): Observable<ProspectModel[]> {
    return this.http.get<Array<ProspectModel>>(ApiRoutes.hubspotRoutes.get.getProspects);
  }

  // public updateCompanyResponsiblePerson(dto: ProspectMapDto): Observable<any> {
  //   return this.http.post<any>(ApiRoutes.hubspotRoutes.update.updateCompanyResponsiblePerson, dto);
  // }

  public updateProspect(dto: ProspectDto): Observable<any> {
    return this.http.post<any>(ApiRoutes.hubspotRoutes.update.updateProspect, dto);
  }

  public createMail(prospectId: number): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.createMail, prospectId);
  }

  public createTrialPeriodScheduleMail(prospectId: number): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.createTrialPeriodScheduleMail, prospectId);
  }
  public createTrialPeriodIntroMail(prospectId: number): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.createTrialPeriodIntroMail, prospectId);
  }

  public createTrialPeriodInCalender(prospectId: number): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.createTrialPeriodInCalender, prospectId);
  }
  
  public removeTrialPeriodInCalender(prospectId: number): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.removeTrialPeriodInCalender, prospectId);
  }

  public createAppointment(prospectId: number): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.createAppointment, prospectId);
  }

  public getProspectDtoFromId(id: number): Observable<ProspectDto> {
    const params: { [key: string]: string } = { id: id.toString() };
    return this.http.get<ProspectDto>(ApiRoutes.hubspotRoutes.get.getProspectDtoFromId, { params: params });
  }

  public createCustomer(dto: ProspectMapDto): Observable<any> {
    return this.http.post<string>(ApiRoutes.hubspotRoutes.post.createCustomer, dto);
  }

  public getProspectNotesByCompanyId(prospectId: number): Observable<ProspectNoteDto[]> {
    const params: { [key: string]: string } = {
      prospectId: prospectId.toString()
    };

    return this.http
      .get<ProspectNoteDto[]>(ApiRoutes.hubspotRoutes.get.getProspectNotesByCompanyId, {
        params: params
      })
      .pipe(
        map(emails => {
          return emails;
        })
      );
  }

  public saveProspectNote(dto: ProspectNoteDto): Observable<any> {
    return this.http.post<ProspectNoteDto>(ApiRoutes.hubspotRoutes.post.saveProspectNote, dto);
  }

  public downloadExcelBenefitCalculation(prospectId: number) {
    return this.http.get(ApiRoutes.hubspotRoutes.get.downloadExcelBenefitCalculation, {
      params: { prospectId },
      responseType: "arraybuffer"
    });
  }

  public getBenefitCalculationFromProspectId(id: number): Observable<BenefitCalculationDto> {
    const params: { [key: string]: string } = { id: id.toString() };
    return this.http.get<BenefitCalculationDto>(ApiRoutes.hubspotRoutes.get.getBenefitCalculationFromProspectId, { params: params });
  }

  public saveBenefitCalculation(dto: BenefitCalculationDto): Observable<any> {
    return this.http.post<BenefitCalculationDto>(ApiRoutes.hubspotRoutes.post.saveBenefitCalculation, dto);
  }

  public getProspectNotes(type: string): Observable<ProspectNoteDto[]> {
    const params: { [key: string]: string } = { type: type };

    return this.http
      .get<ProspectNoteDto[]>(ApiRoutes.hubspotRoutes.get.getProspectNotes, {
        params: params
      })
      .pipe(
        map(items => {
          return items;
        })
      );
  }

  public getBenefitCalculations(): Observable<BenefitCalculationDto[]> {
    const params: { [key: string]: string } = {};

    return this.http
      .get<BenefitCalculationDto[]>(ApiRoutes.hubspotRoutes.get.getBenefitCalculations, {
        params: params
      })
      .pipe(
        map(items => {
          return items;
        })
      );
  }
}
