import { SelectItem } from "primeng/api";

export const activityTypeItems: SelectItem[] = [
  { label: "Ikke angivet", value: -1 },
  { label: "Telefon møde", value: 1 },
  { label: "Kunde møde", value: 2 },
  { label: "Kunde besøg", value: 3 },
  { label: "Opfølgning", value: 4 },
  { label: "Udstilling/messe", value: 5 },
  // { label: "Teori undervisning", value: 6 },
  // { label: "Praktik undervisning", value: 7 },
  { label: "Teams møde", value: 8 },
  { label: "Egen hånd prøveperiode", value: 10 },
  { label: "Teori prøveperiode", value: 11 },
  { label: "Praktisk prøveperiode", value: 12 },
  { label: "Eval og afslut prøveperiode", value: 13 },
  { label: "Opfølgning prøveperiode", value: 14 }
];

export const lessonTypes = [
  { id: 10, name: "Egen hånd", color: "#00008B" },
  { id: 11, name: "Teori", color: "#8B0000" },
  { id: 12, name: "Praktisk", color: "#006400" },
  { id: 13, name: "Eval og afslut", color: "#8B008B" },
  { id: 14, name: "Opfølgning", color: "#4B0082" }
];

export const lessonPlan = [
  { dayOffset: 0, typeId: 11, note: "Der er kun teori denne dag!" }, // Teori med test torsdag
  { dayOffset: 4, typeId: 12 }, // Praktisk undervisning mandag
  { dayOffset: 5, typeId: 12 }, // Praktisk undervisning tirsdag
  { dayOffset: 6, typeId: 10, note: "Vi vil være i telefonisk kontakt efterfølgende" }, // Egen hånd (opfølgning telefonisk) onsdag
  { dayOffset: 7, typeId: 12 }, // Praktisk undervisning torsdag
  { dayOffset: 8, typeId: 12, note: "Vi evaluerer det praktiske forløb" }, // Praktisk undervisning fredag
  { dayOffset: 11, typeId: 10 }, // Laser anvendes på egen hånd mandag
  { dayOffset: 12, typeId: 10 }, // Laser anvendes på egen hånd tirsdag
  { dayOffset: 13, typeId: 13, note: "Vi evaluerer hele forløbet og afslutter hos jer" }, // Evaluering og afslutning onsdag
  { dayOffset: 25, typeId: 14 } // Opfølgning
];

export const salesActivityTypeItems: SelectItem[] = [
  { label: "Ikke angivet", value: -1 },
  { label: "Første telefon kontakt", value: 1 },
  { label: "Første fysiske salgsmøde", value: 2 },
  { label: "Efterfølgende fysiske salgsmøde", value: 3 },
  { label: "Tlf opfølgning på salgsmøde", value: 4 },
  { label: "Efterfølgende telefon kontakt", value: 5 }
];

export const categoryItems: SelectItem[] = [
  { label: "Ikke angivet", value: -1, icon: "" },
  { label: "Grøn", value: 1, icon: "green" },
  { label: "Orange", value: 2, icon: "yellow" },
  { label: "Lysegrøn", value: 3, icon: "lime" },
  { label: "Rød", value: 4, icon: "red" },
  { label: "Lilla", value: 5, icon: "purple" }
];

export const activityTypeItemsWithAll: SelectItem[] = [{ label: "Alle", value: -1 }, ...activityTypeItems];

export interface UserInfo {
  id: number;
  email: string;
  fullName: string;
  firstName: string;
  initials: string;
  colorHex: string;
  trialPeriod: boolean;
  trialPeriodSort: number;
}

export const userInfos: { [key: string]: UserInfo } = {
  Magnus: {
    id: 20,
    email: "mt@neeobovis.dk",
    fullName: "Magnus Timmermann",
    firstName: "Magnus",
    initials: "MT",
    colorHex: "#0000FF", // Blue
    trialPeriod: true,
    trialPeriodSort: 4
  },
  Glennie: {
    id: 2,
    email: "gc@neeobovis.dk",
    fullName: "Glennie Christensen",
    firstName: "Glennie",
    initials: "GC",
    colorHex: "#008000", // Green
    trialPeriod: true,
    trialPeriodSort: 6
  },
  Kevin: {
    id: 29,
    email: "kc@neeobovis.dk",
    fullName: "Kevin Christensen",
    firstName: "Kevin",
    initials: "KC",
    colorHex: "#000000", //Black
    trialPeriod: false,
    trialPeriodSort: 0
  },
  // Hanne: {
  //   id: 28,
  //   email: "hv@neeobovis.dk",
  //   fullName: "Hanne Vang",
  //   firstName: "Hanne",
  //   initials: "HV",
  //   colorHex: "#FFFF00" // Yellow
  // },
  Johanne: {
    id: 27,
    email: "jh@neeobovis.dk",
    fullName: "Johanne Hestbæk",
    firstName: "Johanne",
    initials: "JH",
    colorHex: "#FF0000", // Red
    trialPeriod: true,
    trialPeriodSort: 3
  },
  Nina: {
    id: 21,
    email: "nj@neeobovis.dk",
    fullName: "Nina Jebens",
    firstName: "Nina",
    initials: "NJ",
    colorHex: "#800080", // Purple
    trialPeriod: true,
    trialPeriodSort: 1
  },
  Niels: {
    id: 26,
    email: "ns@neeobovis.dk",
    fullName: "Niels Schultz",
    firstName: "Niels",
    initials: "NS",
    colorHex: "#A52A2A", // Brown
    trialPeriod: true,
    trialPeriodSort: 2
  },
  Claus: {
    id: 25,
    email: "ce@neeobovis.dk",
    fullName: "Claus Elmann",
    firstName: "Claus",
    initials: "CE",
    colorHex: "#FFC107", // Amber Yellow
    trialPeriod: true,
    trialPeriodSort: 8
  },
  Thomas: {
    id: 30,
    email: "th@neeobovis.dk",
    fullName: "Thomas Hansen",
    firstName: "Thomas",
    initials: "TH",
    colorHex: "#808080", // Gray
    trialPeriod: true,
    trialPeriodSort: 7
  },
  Linda: {
    id: 31,
    email: "ld@neeobovis.dk",
    fullName: "Linda Dahlmann",
    firstName: "Linda",
    initials: "LD",
    colorHex: "#FFA500", //Orange
    trialPeriod: true,
    trialPeriodSort: 5
  }
};
