import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, ValidationErrors } from "@angular/forms";
import { Router } from "@angular/router";
import { ProspectDto } from "@apiModels/prospectDto";
import { CustomValidators } from "@globals/classes/custom-validators";
import { HubspotService } from "@globals/services/hubspot.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { danishWeekdays, malkesystemItems, mejeriItems, statusFilterItems, trialPeriodStatusItems, userItems, userItemsTrialPeriod } from "@shared/interfaces-and-enums/shared-data";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { ProspectTrialperiodSchedulerComponent } from "../../prospect-trialperiod-scheduler/prospect-trialperiod-scheduler.component";

@UntilDestroy()
@Component({
  selector: "app-prospect-detail-master-data",
  templateUrl: "./prospect-detail-master-data.component.html",
  styleUrls: ["./prospect-detail-master-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, ConfirmationService]
})
export class ProspectDetailMasterDataComponent implements OnInit {
  @ViewChild(ProspectTrialperiodSchedulerComponent)
  private scheduler!: ProspectTrialperiodSchedulerComponent;

  @Input() prospectId: number;
  @Output() closeDialog = new EventEmitter<ProspectDto | null>();

  close(company: ProspectDto) {
    this.closeDialog.emit(company);
  }

  public mainForm: FormGroup;
  public showFormErrorMessage: boolean = false;
  public pageTitle = "Prospoect redigering";
  public loading = false;
  public errorMessage = "";
  public selectedCompany: ProspectDto;
  public farmUserItem: SelectItem;

  mejeriItems = mejeriItems;
  malkesystemItems = malkesystemItems;
  trialPeriodStatusItems = trialPeriodStatusItems;

  weekYearItems: SelectItem[] = [];
  useritems = userItems;
  trialPeriodStartHourItems: SelectItem[] = [];

  public farmLeadStatusItem: SelectItem;
  public farmMejeriItem: SelectItem;
  public farmMalkesystemItem: SelectItem;
  public trialPeriodStartWeekItem: SelectItem;
  public trialPeriodStatusItem: SelectItem;
  public trialPeriodStartHourItem: SelectItem;
  public trialPeriodStartDayItem: SelectItem;
  trialPeriodDialogVisible = false;

  public userId = +window.sessionStorage.getItem(WindowSessionStorageNames.userId);

  trialPeriodStartDayItems = danishWeekdays;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private hubspotService: HubspotService,
    private confirmationService: ConfirmationService
  ) {
    this.weekYearItems = this.generateWeekYearSelectItems(2025, 2026);
    for (let i = 6; i <= 24; i++) {
      this.trialPeriodStartHourItems.push({ label: `${i}:00`, value: i });
    }
  }

  ngOnInit() {
    if (this.prospectId) {
      this.hubspotService.getProspectDtoFromId(this.prospectId).subscribe(
        (data: ProspectDto) => {
          this.selectedCompany = data;
          this.initFormGroup();
        },
        error => {
          console.error("Error fetching company data", error);
          this.errorMessage = "Error fetching company data";
        }
      );
    } else {
      console.error("Selected farm ID is missing");
      this.errorMessage = "Selected farm ID is missing";
    }
  }

  private initFormGroup() {
    this.farmUserItem = this.userItems.find(user => user.value === this.selectedCompany.ansvarligBrugerId);
    this.farmLeadStatusItem = this.statusFilterItems.find(stage => stage.value === this.selectedCompany.status);
    this.farmMejeriItem = this.mejeriItems.find(stage => stage.value === this.selectedCompany.mejerier);
    this.farmMalkesystemItem = this.malkesystemItems.find(stage => stage.value === this.selectedCompany.malkesystem);
    this.trialPeriodStartWeekItem = this.weekYearItems.find(stage => stage.value === this.selectedCompany.trialPeriodStartWeekYearId);
    this.trialPeriodStatusItem = this.trialPeriodStatusItems.find(stage => stage.value === this.selectedCompany.trialPeriodStatus);
    this.trialPeriodStartHourItem = this.trialPeriodStartHourItems.find(stage => stage.value === this.selectedCompany.trialPeriodStartHour);
    this.trialPeriodStartDayItem = this.trialPeriodStartDayItems.find(stage => stage.value === this.selectedCompany.trialPeriodStartDay);

    const selectedTrialPeriodUserValues = this.selectedCompany.trialPeriodUserIds ? this.selectedCompany.trialPeriodUserIds.split(",").map(id => Number(id.trim())) : [];

    const selectedTrialPeriodUsers = userItemsTrialPeriod.filter(user => selectedTrialPeriodUserValues.includes(user.value));

    this.mainForm = new FormGroup({
      contactName: new FormControl(this.selectedCompany.contactName),
      contactEmail: new FormControl(this.selectedCompany.contactEmail, CustomValidators.email()),
      contactPhone: new FormControl(this.selectedCompany.contactPhone),
      status: new FormControl(this.farmLeadStatusItem),
      ansvarligBrugerId: new FormControl(this.farmUserItem),
      race: new FormControl(this.selectedCompany.race),
      mejerier: new FormControl(this.farmMejeriItem),
      noter: new FormControl(this.selectedCompany.noter),
      contactName1: new FormControl(this.selectedCompany.contactName1),
      contactEmail1: new FormControl(this.selectedCompany.contactEmail1, CustomValidators.email()),
      contactPhone1: new FormControl(this.selectedCompany.contactPhone1),
      malkesystem: new FormControl(this.farmMalkesystemItem),
      trialPeriodUserIds: new FormControl(selectedTrialPeriodUsers),
      trialPeriodStartWeekYearId: new FormControl(this.trialPeriodStartWeekItem),
      trialPeriodStatus: new FormControl(this.trialPeriodStatusItem),
      trialPeriodNotes: new FormControl(this.selectedCompany.trialPeriodNotes),
      trialPeriodNotesInternal: new FormControl(this.selectedCompany.trialPeriodNotesInternal),
      trialPeriodStartHour: new FormControl(this.trialPeriodStartHourItem),
      trialPeriodStartDay: new FormControl(this.trialPeriodStartDayItem),
      trialPeriodLanguage: new FormControl(this.selectedCompany.trialPeriodLanguage),
      trialPeriodPersons: new FormControl(this.selectedCompany.trialPeriodPersons, [CustomValidators.digitsOnly()]),
      trialPeriodHideInCalender: new FormControl(this.selectedCompany.trialPeriodHideInCalender)
    });

    setTimeout(() => {
      this.mainForm.markAsPristine();
      this.cd.detectChanges();
    }, 100);
  }

  generateWeekYearSelectItems(startYear: number, endYear: number): SelectItem[] {
    const selectItems: SelectItem[] = [{ label: "Ikke valgt", value: null }];
    for (let year = startYear; year <= endYear; year++) {
      for (let week = 1; week <= 52; week++) {
        const weekYearId = year * 100 + week;
        selectItems.push({
          label: `Uge ${week}, ${year}`,
          value: weekYearId
        });
      }
    }
    return selectItems;
  }

  public userItems = userItems;

  public statusFilterItems = statusFilterItems;

  public userItemsTrialPeriod = userItemsTrialPeriod;

  public get contactName() {
    return this.mainForm.get("contactName");
  }
  public get contactEmail() {
    return this.mainForm.get("contactEmail");
  }
  public get contactPhone() {
    return this.mainForm.get("contactPhone");
  }

  public get status() {
    return this.mainForm.get("status");
  }

  public get ansvarligBrugerId() {
    return this.mainForm.get("ansvarligBrugerId");
  }

  public get mejerier() {
    return this.mainForm.get("mejerier");
  }

  public get race() {
    return this.mainForm.get("race");
  }

  public get noter() {
    return this.mainForm.get("noter");
  }

  public get contactName1() {
    return this.mainForm.get("contactName1");
  }

  public get contactEmail1() {
    return this.mainForm.get("contactEmail1");
  }

  public get contactPhone1() {
    return this.mainForm.get("contactPhone1");
  }

  public get malkesystem() {
    return this.mainForm.get("malkesystem");
  }

  public get trialPeriodUserIds() {
    return this.mainForm.get("trialPeriodUserIds");
  }

  public get trialPeriodStartWeekYearId() {
    return this.mainForm.get("trialPeriodStartWeekYearId");
  }

  public get trialPeriodStatus() {
    return this.mainForm.get("trialPeriodStatus");
  }

  public get trialPeriodNotes() {
    return this.mainForm.get("trialPeriodNotes");
  }

  public get trialPeriodStartHour() {
    return this.mainForm.get("trialPeriodStartHour");
  }
  public get trialPeriodStartDay() {
    return this.mainForm.get("trialPeriodStartDay");
  }

  public get trialPeriodLanguage() {
    return this.mainForm.get("trialPeriodLanguage");
  }

  public get trialPeriodPersons() {
    return this.mainForm.get("trialPeriodPersons");
  }

  public get trialPeriodNotesInternal() {
    return this.mainForm.get("trialPeriodNotesInternal");
  }

  public get trialPeriodHideInCalender() {
    return this.mainForm.get("trialPeriodHideInCalender");
  }

  private checkAndValidateForm(): boolean {
    if (this.mainForm.invalid) {
      Object.keys(this.mainForm.controls).forEach(cName => this.mainForm.controls[cName].markAsTouched());
      this.showFormErrorMessage = true;
      return false;
    }
    this.showFormErrorMessage = false;
    return true;
  }

  public getFormValidationErrors() {
    console.log("%c ==>> Validation Errors: ", "color: red; font-weight: bold; font-size:25px;");
    let totalErrors = 0;
    Object.keys(this.mainForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.mainForm.get(key).errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach(keyError => {
          console.log("Key control: " + key + ", keyError: " + keyError + ", err value: ", controlErrors[keyError]);
        });
      }
    });
    console.log("Number of errors: ", totalErrors);
  }

  public saveItem(gemOgLuk: boolean = true) {
    if (!this.checkAndValidateForm()) return;

    Object.keys(this.mainForm.controls).forEach(key => {
      const controlValue = this.mainForm.get(key).value;

      // Check if the control value is an object (dropdown selected item)
      if (controlValue && typeof controlValue === "object" && controlValue.hasOwnProperty("value")) {
        this.selectedCompany[key] = controlValue.value;
      } else {
        this.selectedCompany[key] = controlValue;
      }
    });

    this.selectedCompany.trialPeriodUserIds = this.trialPeriodUserIds.value.map((item: { value: string }) => item.value).join(",");

    if (this.mainForm.get("trialPeriodPersons").value === "") {
      this.selectedCompany.trialPeriodPersons = null;
    }

    this.hubspotService.updateProspect(this.selectedCompany).subscribe({
      next: () => {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Data blev gemt"
        });

        if (gemOgLuk) {
          this.close(this.selectedCompany);
        } else {
          this.initFormGroup();
          this.scheduler.refreshData();
        }
      },
      error: err => {
        console.error("Error saving company data", err);
        this.errorMessage = "Error saving company data";
      }
    });
  }

  confirmCreateCustomer() {
    this.confirmationService.confirm({
      message: `Er du sikker på, at du vil oprette [<b> ${this.selectedCompany.brugerNavn}</b>] som kunde?`,
      header: "Bekræftelse",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Ja",
      rejectLabel: "Nej",
      acceptButtonStyleClass: "p-button-primary",
      rejectButtonStyleClass: "p-button-secondary",
      accept: () => {
        this.createCustomer();
      }
    });
  }

  public createCustomer() {
    this.hubspotService.createCustomer(this.selectedCompany).subscribe(customerId => {
      this.router.navigate(["/superadmin/customers", customerId, "main"]);
    });
  }

  public createMail() {
    this.hubspotService.createMail(this.selectedCompany.id).subscribe(messageId => {
      let encodedMailId = encodeURIComponent(messageId.messageId);

      encodedMailId = encodedMailId.replace(/_/g, "%2B");

      const url = `https://outlook.office.com/mail/compose/${encodedMailId}`;

      console.log(url);

      window.open(url, "mailWindow");
    });
  }

  public createAppointment() {
    this.hubspotService.createAppointment(this.selectedCompany.id).subscribe(calendarId => {
      let encodedEventId = encodeURIComponent(calendarId.calendarId);

      encodedEventId = encodedEventId.replace(/_/g, "%2B");

      const url = `https://outlook.office.com/calendar/item/${encodedEventId}`;

      https: console.log(url);

      window.open(url, "mailWindow");
    });
  }

  public createTrialPeriodScheduleMail() {
    this.hubspotService.createTrialPeriodScheduleMail(this.selectedCompany.id).subscribe(messageId => {
      let encodedMailId = encodeURIComponent(messageId.messageId);

      encodedMailId = encodedMailId.replace(/_/g, "%2B");

      const url = `https://outlook.office.com/mail/compose/${encodedMailId}`;

      console.log(url);

      window.open(url, "mailWindow");
    });
  }

  public createTrialPeriodIntroMail() {
    this.hubspotService.createTrialPeriodIntroMail(this.selectedCompany.id).subscribe(messageId => {
      let encodedMailId = encodeURIComponent(messageId.messageId);

      encodedMailId = encodedMailId.replace(/_/g, "%2B");

      const url = `https://outlook.office.com/mail/compose/${encodedMailId}`;

      console.log(url);

      window.open(url, "mailWindow");
    });
  }

  public createTrialPeriodInCalender() {
    this.hubspotService.createTrialPeriodInCalender(this.selectedCompany.id).subscribe(messageId => {
      this.messageService.add({
        severity: "success",
        summary: "Success",
        detail: "Trial period created in calendar"
      });
    });
  }
  public removeTrialPeriodInCalender() {}

  onNewProspectIdSelected(prospectId: number): void {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([`/trialperiodscheduler`, prospectId]);
    });
  }

  handleTrialPeriodDialogCloseDialog(saved: boolean): void {
    // Hvis gemning udføres og trialPeriodDaysCreated ikke er sat
    if (saved && !this.selectedCompany.trialPeriodDaysCreated) {
      this.selectedCompany.trialPeriodDaysCreated = true;

      // Tving UI-opdatering for at sikre, at ændringer reflekteres
      this.cd.detectChanges();
    }

    // Luk dialogen
    this.trialPeriodDialogVisible = false;
  }
}
