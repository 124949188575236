import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { HtmlNoteDto } from "@apiModels/htmlNoteDto";
import { ActivityService } from "@globals/services/activity.service";
import { userItems } from "@shared/interfaces-and-enums/shared-data";
import { RichTextEditorComponent } from "@syncfusion/ej2-angular-richtexteditor";

@Component({
  selector: "app-html-note-edit",
  templateUrl: "./html-note-edit.component.html",
  styleUrls: ["./html-note-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlNoteEditComponent {
  @ViewChild("richTextEditorNewNote") public richTextEditorNewNote!: RichTextEditorComponent;

  @Input() note: HtmlNoteDto | null = null;
  @Output() save: EventEmitter<HtmlNoteDto> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  public userItems = userItems;
  public mentionUsers: { [key: string]: Object }[] = [];
  public mentionFields: Object = { text: "name", value: "id" };

  public isSaving = false;

  public toolbarSettings = {
    items: ["Bold", "Italic", "Underline", "|", "Formats", "Alignments", "|", "OrderedList", "UnorderedList", "|", "CreateLink", "|", "Undo", "Redo"],
    enable: true
  };

  constructor(
    private activityService: ActivityService,
    private sanitizer: DomSanitizer
  ) {
    this.mentionUsers = userItems.map(user => ({
      id: user.value,
      name: user.label
    }));
  }

  getSafeHtml(noteBody: string) {
    return this.sanitizer.bypassSecurityTrustHtml(noteBody);
  }

  getUserName(userId: number): string {
    const user = this.userItems.find(u => u.value === userId);
    return user ? user.label : "Unknown";
  }

  onSave(doMention: boolean = true): void {
    if (!this.note) {
      console.error("Note is null or undefined.");
      return;
    }

    this.isSaving = true;

    // Opdaterer note med mention-status
    const updatedNote: HtmlNoteDto = {
      ...this.note,
      doMention: doMention
    };

    this.activityService.updateHtmlNote(updatedNote).subscribe({
      next: result => {
        this.save.emit(result);
        this.closeDialog.emit();
        this.isSaving = false;
      },
      error: error => {
        console.error("Error updating note:", error);
        this.isSaving = false;
      }
    });
  }

  containsMention(): boolean {
    if (!this.note || !this.note.note) {
      return false;
    }

    // Matcher mentions baseret på `e-mention-chip` klassen
    return this.note.note.includes('class="e-mention-chip"');
  }

  deleteNote() {
    const confirmDelete = window.confirm("Er du sikker på du ønsker at slette noten?");
    if (confirmDelete) {
      this.note.deleted = true;
      this.activityService.updateHtmlNote(this.note).subscribe({
        next: updatedNote => {
          this.save.emit(updatedNote);
          this.closeDialog.emit();
          this.isSaving = false;
        },
        error: error => {
          console.error("Error updating note:", error);
          this.isSaving = false;
        }
      });
    }
  }

  onCancel(): void {
    this.cancel.emit();
    this.closeDialog.emit();
  }
}
