import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivityDto } from "@apiModels/activityDto";
import { ActivityFilterDto } from "@apiModels/activityFilterDto";
import { HtmlNoteDto } from "@apiModels/htmlNoteDto";
import { KanbanItemDto } from "@apiModels/kanbanItemDto";
import { KanbanItemFilterDto } from "@apiModels/kanbanItemFilterDto";
import { ProjectTaskDto } from "@apiModels/projectTaskDto";
import { ProjectTaskNoteDto } from "@apiModels/projectTaskNoteDto";
import { ProspectTrialPeriodDayDto } from "@apiModels/prospectTrialPeriodDayDto";
import { ProspectTrialPeriodDto } from "@apiModels/prospectTrialPeriodDto";
import { TeamPlanDto } from "@apiModels/teamPlanDto";
import { TeamWeekPlanDto } from "@apiModels/teamWeekPlanDto";
import { TrialPeriodPlanDto } from "@apiModels/trialPeriodPlanDto";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { map, Observable } from "rxjs";
import { DataForKanban } from "src/app/components/kanban/KanbanData";

@Injectable({ providedIn: "root" })
export class ActivityService {
  constructor(private http: HttpClient) {}

  // public getActivities(filter: ActivityFilterDto): Observable<ActivityDto[]> {
  //   const params: { [key: string]: string } = {};

  //   return this.http
  //     .get<ActivityDto[]>(ApiRoutes.activityRoutes.get.getActivities, {
  //       params: params
  //     })
  //     .pipe(
  //       map(items => {
  //         return items;
  //       })
  //     );
  // }

  // public getActivities(filter: ActivityFilterDto): Observable<ActivityDto[]> {
  //   const params: { [key: string]: string } = {};

  //   if (filter.activityAssignmentUserId && filter.activityAssignmentUserId.length > 0) {
  //     params["activityAssignmentUserId"] = filter.activityAssignmentUserId.join(",");
  //   }

  //   return this.http.get<ActivityDto[]>(ApiRoutes.activityRoutes.get.getActivities, { params }).pipe(map(items => items));
  // }

  public getActivities(filter: ActivityFilterDto): Observable<ActivityDto[]> {
    return this.http.post<ActivityDto[]>(ApiRoutes.activityRoutes.get.getActivities, filter).pipe(map(items => items));
  }

  public getActivityFromId(id: number): Observable<ActivityDto> {
    const params: { [key: string]: string } = { id: id.toString() };
    return this.http.get<ActivityDto>(ApiRoutes.activityRoutes.get.getActivityFromId, { params: params });
  }

  public updateActivity(dto: ActivityDto): Observable<any> {
    return this.http.post<any>(ApiRoutes.activityRoutes.update.updateActivity, dto);
  }

  public getProspectActivities(prospectId: number): Observable<ActivityDto[]> {
    const params: { [key: string]: string } = { prospectId: prospectId.toString() };

    return this.http
      .get<ActivityDto[]>(ApiRoutes.activityRoutes.get.getProspectActivities, {
        params: params
      })
      .pipe(
        map(items => {
          return items;
        })
      );
  }

  public getAllKanbanItems(filter: KanbanItemFilterDto): Observable<KanbanItemDto[]> {
    return this.http.post<KanbanItemDto[]>(ApiRoutes.activityRoutes.get.getAllKanbanItems, filter).pipe(map(items => items));
  }

  public getProjectTaskById(id: number): Observable<ProjectTaskDto> {
    const params: { [key: string]: string } = { id: id.toString() };
    return this.http.get<ActivityDto>(ApiRoutes.activityRoutes.get.getProjectTaskById, { params: params });
  }

  public updateTasks(tasks: DataForKanban[]): Observable<any> {
    return this.http.post(ApiRoutes.activityRoutes.update.updateTasks, tasks);
  }

  public updateProjectTask(dto: ProjectTaskDto): Observable<KanbanItemDto> {
    return this.http.post<KanbanItemDto>(ApiRoutes.activityRoutes.update.updateProjectTask, dto);
  }

  public updateProjectTaskNote(dto: ProjectTaskNoteDto): Observable<any> {
    return this.http.post<any>(ApiRoutes.activityRoutes.update.updateProjectTaskNote, dto);
  }

  public getTeamPlan(): Observable<TeamPlanDto> {
    return this.http.get<TeamPlanDto>(ApiRoutes.activityRoutes.get.getTeamPlan).pipe(map(items => items));
  }

  public updateTeamWeekPlans(plans: TeamWeekPlanDto[]): Observable<TeamPlanDto> {
    return this.http.post<TeamPlanDto>(ApiRoutes.activityRoutes.update.updateTeamWeekPlans, plans);
  }

  // public getTrialPeriodPlan(): Observable<TrialPeriodPlanDto> {
  //   return this.http.get<TrialPeriodPlanDto>(ApiRoutes.activityRoutes.get.getTrialPeriodPlan).pipe(map(items => items));
  // }

  public getTrialPeriodPlan(weekStart?: number, yearStart?: number): Observable<TrialPeriodPlanDto> {
    let params = new HttpParams();

    if (weekStart !== undefined && weekStart !== null) {
      params = params.set("weekStart", weekStart.toString());
    }

    if (yearStart !== undefined && yearStart !== null) {
      params = params.set("yearStart", yearStart.toString());
    }

    return this.http.get<TrialPeriodPlanDto>(ApiRoutes.activityRoutes.get.getTrialPeriodPlan, { params }).pipe(map(items => items));
  }

  public getTrialPeriod(id: number): Observable<ProspectTrialPeriodDto> {
    const params: { [key: string]: string } = { id: id.toString() };
    return this.http.get<ProspectTrialPeriodDto>(ApiRoutes.activityRoutes.get.getTrialPeriod, { params: params });
  }

  public updateTrialPeriod(dto: ProspectTrialPeriodDto): Observable<ProspectTrialPeriodDto> {
    return this.http.post<ProspectTrialPeriodDto>(ApiRoutes.activityRoutes.update.updateTrialPeriod, dto);
  }

  public getProspectTrialPeriodDays(prospectId: number): Observable<ProspectTrialPeriodDayDto[]> {
    const params = new HttpParams().set("prospectId", prospectId.toString());

    return this.http.get<ProspectTrialPeriodDayDto[]>(ApiRoutes.activityRoutes.get.getProspectTrialPeriodDays, { params });
  }

  public getAllHtmlNotes(customerId: number, refTypeId: number, refId: number): Observable<HtmlNoteDto[]> {
    const params = new HttpParams({
      fromObject: { customerId: customerId.toString(), refTypeId: refTypeId.toString(), refId: refId.toString() }
    });

    return this.http.get<HtmlNoteDto[]>(ApiRoutes.activityRoutes.get.getAllHtmlNotes, { params });
  }

  public updateHtmlNote(dto: HtmlNoteDto): Observable<HtmlNoteDto> {
    return this.http.post<HtmlNoteDto>(ApiRoutes.activityRoutes.update.updateHtmlNote, dto);
  }
}
