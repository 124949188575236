import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser"; // Import DomSanitizer
import { ActivatedRoute } from "@angular/router";
import { HtmlNoteDto } from "@apiModels/htmlNoteDto";
import { RefType } from "@enums/RefType";
import { ActivityService } from "@globals/services/activity.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { userItems } from "@shared/interfaces-and-enums/shared-data";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { MessageService } from "primeng/api";
import { finalize } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-customer-note-list",
  templateUrl: "./superadmin-customer-notelist.component.html",
  styleUrls: ["./superadmin-customer-notelist.component.scss"],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuperAdminCustomerNoteListComponent implements OnInit {
  public loading = true;
  private customerId: number;
  public notes: HtmlNoteDto[] = [];
  public selectedNote: HtmlNoteDto | null = null;
  public editDialogVisible: boolean = false;

  userItems = userItems;

  constructor(
    private activityService: ActivityService,
    private activeRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private sanitizer: DomSanitizer // Tilføj DomSanitizer i constructoren
  ) {}

  ngOnInit() {
    this.customerId = this.activeRoute.parent.snapshot.params.id;
    this.loadNotes();
  }

  /**
   * Henter noter til den specifikke kunde
   */
  loadNotes(): void {
    this.loading = true;

    this.activityService
      .getAllHtmlNotes(this.customerId, RefType.Customer, this.customerId)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.loading = false))
      )
      .subscribe({
        next: (data: HtmlNoteDto[]) => {
          this.notes = data || []; // Fallback til en tom liste
          this.sortNotes(); // Nyeste noter øverst
          this.cd.markForCheck();
        },
        error: error => {
          console.error("Error loading notes:", error);
          this.messageService.add({
            severity: "error",
            summary: "Fejl",
            detail: "Kunne ikke hente noter."
          });
        }
      });
  }

  /**
   * Sorterer noter, så nyeste vises øverst.
   */
  private sortNotes(): void {
    this.notes = this.notes
      .filter(note => !note.deleted) // Filtrér væk noter hvor deleted = true
      .sort((a, b) => new Date(b.dateCreatedUtc).getTime() - new Date(a.dateCreatedUtc).getTime());
  }

  /**
   * Returnerer sikkert HTML-indhold
   */
  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  // Get username by ID
  getUserName(userId: number): string {
    const user = this.userItems.find(u => u.value === userId);
    return user ? user.label : "Ukendt bruger";
  }

  public onDialogOpen(): void {
    console.log("Dialog opened");
  }
  public onDialogClose(): void {
    this.closeDialog();
    console.log("Dialog closed");
  }

  openNoteDialog(): void {
    const user = this.userItems.find(u => u.value === +window.sessionStorage.getItem(WindowSessionStorageNames.userId));
    this.selectedNote = {
      id: 0,
      customerId: this.customerId,
      refTypeId: RefType.Customer,
      refId: this.customerId,
      createdByUserId: +user.value,
      userName: user.label,
      dateCreatedUtc: new Date().toISOString(),
      deleted: false,
      note: ""
    };
    this.editDialogVisible = true;
    this.cd.markForCheck();
  }

  editNote(note: HtmlNoteDto): void {
    // Kopier noten for at undgå direkte mutation
    this.selectedNote = { ...note };
    this.editDialogVisible = true;
    this.cd.markForCheck();
  }

  onNoteSaved(updatedNote: HtmlNoteDto): void {
    // Opdater listen af noter med den opdaterede note
    const index = this.notes.findIndex(n => n.id === updatedNote.id);
    if (index !== -1) {
      this.notes[index] = updatedNote;
    } else {
      // Hvis noten er ny, tilføj den til listen
      this.notes.unshift(updatedNote);
    }
    this.sortNotes(); // Sørg for, at nyeste noter er øverst
    this.closeDialog();
  }

  closeDialog(): void {
    this.selectedNote = null;
    this.editDialogVisible = false;
    this.cd.detectChanges();
  }

  handleCloseDialog(): void {
    this.selectedNote = null;
    this.editDialogVisible = false;
    this.cd.detectChanges();
  }
}
