<div *ngIf="note" class="task-dialog-content">
  <!-- Knapper øverst -->
  <div class="buttons top-buttons">
    <button class="ml-2" (click)="onSave(true)">💾 Gem</button>
    <button *ngIf="containsMention() && note?.id > 0" class="ml-2" (click)="onSave(false)">💾 Gem (uden mention)</button>
    <button class="ml-2" (click)="onCancel()">❌ Fortryd</button>
    <button *ngIf="note?.id > 0" class="ml-2" (click)="deleteNote()">🗑️ Slet</button>
  </div>

  <!-- Rich Text Editor -->
  <ejs-richtexteditor
    #richTextEditorNewNote
    id="richTextEditorNewNote"
    [enableToolbar]="false"
    [(value)]="note.note"
    [enableAutoResize]="true"
    [enableInsertBR]="true"
    [insertImageSettings]="{ saveFormat: 'Base64' }"
  ></ejs-richtexteditor>
  <ejs-mention [dataSource]="mentionUsers" [fields]="mentionFields" [target]="'#richTextEditorNewNote'"></ejs-mention>

  <!-- Knapper nederst -->
  <div class="buttons bottom-buttons">
    <button class="ml-2" (click)="onSave(true)">💾 Gem</button>
    <button *ngIf="containsMention() && note?.id > 0" class="ml-2" (click)="onSave(false)">💾 Gem (uden mention)</button>
    <button class="ml-2" (click)="onCancel()">❌ Fortryd</button>
    <button *ngIf="note?.id > 0" class="ml-2" (click)="deleteNote()">🗑️ Slet</button>
  </div>
</div>
