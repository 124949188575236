<div class="customer-notes-container">
  <!-- Titel -->
  <div class="header">
    <h3 class="header-title">Noter</h3>
    <button pButton pRipple label="Ny Note" icon="pi pi-plus" class="p-button-success new-note-btn" (click)="openNoteDialog()"></button>
  </div>

  <!-- Historik -->
  <div class="notes-history">
    <div *ngFor="let note of notes" class="note">
      <div class="note-header">
        <p class="note-author"><strong>Oprettet af:</strong> {{ getUserName(note.createdByUserId) }} <strong>den</strong> {{ note.dateCreatedUtc | biDate: "YYYY-MM-DD hh:mm" }}</p>
        <button pButton pRipple label="Rediger" icon="pi pi-pencil" class="p-button-text edit-button" (click)="editNote(note)"></button>
      </div>
      <div class="note-content" [innerHTML]="getSafeHtml(note.note)"></div>
    </div>
  </div>

  <!-- Ingen noter -->
  <div *ngIf="notes.length === 0 && !loading" class="no-notes">
    <p>Der er ingen noter at vise endnu.</p>
  </div>

  <!-- Loading -->
  <div *ngIf="loading" class="loading-spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
</div>

<!-- <p-dialog
  *ngIf="editDialogVisible"
  [header]="'Rediger note'"
  [(visible)]="editDialogVisible"
  [modal]="true"
  (onHide)="handleCloseDialog(null)"
  [style]="{ width: '90rem', minHeight: '90vh' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <app-html-note-edit [note]="selectedNote" (save)="onNoteSaved($event)" (cancel)="closeDialog()" (closeDialog)="handleCloseDialog()" (onClose)="handleCloseDialog()"></app-html-note-edit>
</p-dialog> -->

<ejs-dialog
  id="dialog"
  [(visible)]="editDialogVisible"
  [width]="'95%'"
  [height]="'95%'"
  [isModal]="true"
  (open)="onDialogOpen()"
  (close)="onDialogClose()"
  [animationSettings]="{ effect: 'Zoom' }"
  [header]="headerTemplate"
  [content]="contentTemplate"
>
  <ng-template #headerTemplate>
    <div class="dialog-header">
      <span>{{ selectedTask?.Navn }}</span>
      <button class="e-dialog-close" (click)="isDialogVisible = false; onDialogClose(null)">✖</button>
    </div>
  </ng-template>

  <ng-template #contentTemplate>
    <app-html-note-edit [note]="selectedNote" (save)="onNoteSaved($event)" (cancel)="closeDialog()" (closeDialog)="handleCloseDialog()" (onClose)="handleCloseDialog()"></app-html-note-edit>
  </ng-template>
</ejs-dialog>

<!-- Redigeringsdialog -->
