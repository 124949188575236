<p-accordion [activeIndex]="0">
  <p-accordionTab header="Filtrer">
    <div class="flex-container">
      <div class="p-field flex-item">
        <label for="userFilter">Tildelt : </label>
        <p-dropdown id="userFilter" class="filter-dropdown ml-3" optionLabel="label" dataKey="value" [options]="userItemsWithAllAndNone" [(ngModel)]="selectedUserItem"></p-dropdown>
      </div>

      <div class="p-field flex-item">
        <label for="activityFilter">Aktivitet : </label>
        <p-multiSelect id="activityFilter" class="filter-multiselect ml-3" optionLabel="label" dataKey="value" [options]="activityTypeItems" [(ngModel)]="selectedActivityTypeItems"></p-multiSelect>
      </div>

      <div class="p-field flex-item">
        <label for="salesActivityFilter">Salgs Aktivitet : </label>
        <p-multiSelect
          id="salesActivityFilter"
          class="filter-multiselect ml-3"
          optionLabel="label"
          dataKey="value"
          [options]="salesActivityTypeItems"
          [(ngModel)]="selectedSalesActivityTypeItems"
        ></p-multiSelect>
      </div>

      <div class="p-field flex-item">
        <button pButton type="button" (click)="handleFilterChange()">Filtrer</button>
        <button pButton class="ml-2" type="button" (click)="resetFilter(true)">Nulstill filter</button>
      </div>

      <!-- Farveoversigt - placeret helt til højre -->
      <div class="color-overview-horizontal flex-item color-overview-right">
        <div class="color-overview-content">
          <div *ngFor="let userKey of userColorsKeys" class="color-item">
            <span class="color-box" [style.background-color]="userInfos[userKey].colorHex"></span>
            <span class="user-name">{{ userInfos[userKey].fullName }}</span>
          </div>
        </div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<div class="col-12">
  <div class="card">
    <ejs-schedule
      #scheduleObj
      width="100%"
      height="100%"
      [selectedDate]="selectedDate"
      [eventSettings]="eventSettings"
      rowAutoHeight="true"
      timeFormat="HH:mm"
      (eventRendered)="onEventRendered($event)"
      (eventClick)="onEventClick($event)"
      (cellClick)="addNewActivity($event)"
      (created)="onCreate()"
      (navigating)="onNavigating($event)"
      (moreEventsClick)="onMoreEventsClick($event)"
    >
      <e-views>
        <e-view option="Day" startHour="06:00" endHour="24:00"></e-view>
        <e-view option="Week" startHour="06:00" endHour="24:00"></e-view>
        <e-view option="WorkWeek" startHour="06:00" endHour="24:00"></e-view>
        <!-- <e-view option="WorkWeek" [interval]="2" startHour="08:00" endHour="16:00"></e-view> -->
        <e-view option="Month" [showWeekNumber]="true" [showWeekend]="showWeekend"></e-view>
      </e-views>
    </ejs-schedule>
  </div>
</div>

<p-dialog
  *ngIf="dialogVisible"
  [header]="'Rediger opgave'"
  [(visible)]="dialogVisible"
  [modal]="true"
  (onHide)="handleCloseDialog(null)"
  [style]="{ width: '90rem', minHeight: '90vh' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <app-activity-detail *ngIf="dialogVisible" [activityId]="selectedItem.id" [activity]="selectedItem" (closeDialog)="handleCloseDialog()" (onClose)="handleCloseDialog()"></app-activity-detail>
</p-dialog>

<p-dialog
  *ngIf="trialPeriodDialogVisible"
  [header]="'Rediger prøve periode'"
  [(visible)]="trialPeriodDialogVisible"
  [modal]="true"
  (onHide)="handleTrialPeriodDialogCloseDialog()"
  [style]="{ width: '75vw', minWidth: '1200px', minHeight: '95vh' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [maximizable]="true"
>
  <!-- <app-trial-period-calendar
    [trialPeriodId]="selectedTrialPeriodId"
    (closeDialog)="handleTrialPeriodDialogCloseDialog($event)"
    (onClose)="handleTrialPeriodDialogCloseDialog()"
  ></app-trial-period-calendar> -->
  <app-prospect-detail [prospectId]="selectedTrialPeriodId" (closeDialog)="handleCloseDialog($event)" (farmDialogVisibleChange)="trialPeriodDialogVisible = false"></app-prospect-detail>
</p-dialog>
