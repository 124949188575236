import { HttpClient, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { BadgeModule } from "primeng/badge";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { CarouselModule } from "primeng/carousel";
import { CascadeSelectModule } from "primeng/cascadeselect";
import { ChartModule } from "primeng/chart";
import { CheckboxModule } from "primeng/checkbox";
import { ChipModule } from "primeng/chip";
import { ChipsModule } from "primeng/chips";
import { ColorPickerModule } from "primeng/colorpicker";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmPopupModule } from "primeng/confirmpopup";
import { ContextMenuModule } from "primeng/contextmenu";
import { DataViewModule } from "primeng/dataview";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { DropdownModule } from "primeng/dropdown";
import { FieldsetModule } from "primeng/fieldset";
import { FileUploadModule } from "primeng/fileupload";
import { GalleriaModule } from "primeng/galleria";
import { ImageModule } from "primeng/image";
import { InplaceModule } from "primeng/inplace";
import { InputMaskModule } from "primeng/inputmask";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";
import { KnobModule } from "primeng/knob";
import { ListboxModule } from "primeng/listbox";
import { MegaMenuModule } from "primeng/megamenu";
import { MenuModule } from "primeng/menu";
import { MenubarModule } from "primeng/menubar";
import { MessageModule } from "primeng/message";
import { MessagesModule } from "primeng/messages";
import { MultiSelectModule } from "primeng/multiselect";
import { OrderListModule } from "primeng/orderlist";
import { OrganizationChartModule } from "primeng/organizationchart";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { PanelModule } from "primeng/panel";
import { PanelMenuModule } from "primeng/panelmenu";
import { PasswordModule } from "primeng/password";
import { PickListModule } from "primeng/picklist";
import { ProgressBarModule } from "primeng/progressbar";
import { RadioButtonModule } from "primeng/radiobutton";
import { RatingModule } from "primeng/rating";
import { RippleModule } from "primeng/ripple";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ScrollTopModule } from "primeng/scrolltop";
import { SelectButtonModule } from "primeng/selectbutton";
import { SidebarModule } from "primeng/sidebar";
import { SkeletonModule } from "primeng/skeleton";
import { SlideMenuModule } from "primeng/slidemenu";
import { SliderModule } from "primeng/slider";
import { SplitButtonModule } from "primeng/splitbutton";
import { SplitterModule } from "primeng/splitter";
import { StepsModule } from "primeng/steps";
import { StyleClassModule } from "primeng/styleclass";
import { TableModule } from "primeng/table";
import { TabMenuModule } from "primeng/tabmenu";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { TerminalModule } from "primeng/terminal";
import { TieredMenuModule } from "primeng/tieredmenu";
import { TimelineModule } from "primeng/timeline";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { ToolbarModule } from "primeng/toolbar";
import { TooltipModule } from "primeng/tooltip";
import { TreeModule } from "primeng/tree";
import { TreeSelectModule } from "primeng/treeselect";
import { TreeTableModule } from "primeng/treetable";
import { VirtualScrollerModule } from "primeng/virtualscroller";
import { Observable } from "rxjs";
import { AppRoutingModule } from "./app-routing.module";

import { RouteReuseStrategy } from "@angular/router";
import { MsalBroadcastService, MsalGuard, MsalModule, MsalRedirectComponent, MsalService } from "@azure/msal-angular";
import { IPublicClientApplication, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { CoreModule } from "@core/core.module";
import { MasterDetailReuseStrategy } from "@core/routing/MasterDetailReuseStrategy";
import { AppCanActivateGuard } from "@core/routing/guards/app-can-activate.guard";
import { AppCanDeactivateGuard } from "@core/routing/guards/app-can-deactivate.guard";
import { BiCountryId } from "@enums/BiLanguageAndCountryId";
import { FeaturesModule } from "@features/features.module";
import { BiSpinnerModule } from "@globals/components/spinner/bi-spinner.module";
import { AUTHENTICATION_SERVICE_TOKEN } from "@globals/security/TokenAuthenticationService";
import { CustomerService } from "@globals/services/customer.service";
import { UserService } from "@globals/services/user.service";
import { ApiRoutes } from "@shared/classes/ApiRoutes";
import { SharedModule } from "@shared/shared.module";
import { MentionModule } from "@syncfusion/ej2-angular-dropdowns";
import { KanbanModule } from "@syncfusion/ej2-angular-kanban";
import { DialogModule as SyncfusionDialogModule } from "@syncfusion/ej2-angular-popups";
import { HtmlEditorService, ImageService, LinkService, QuickToolbarService, RichTextEditorModule, ToolbarService } from "@syncfusion/ej2-angular-richtexteditor";
import { RecurrenceEditorModule, ScheduleModule } from "@syncfusion/ej2-angular-schedule";
import { ConfirmationService, MessageService } from "primeng/api";
import { CertificationService } from "side-projects/neeo-bovis-public-app/src/services/certification.service";
import { neeobovisAppInitializer } from "src/neeobovisAppInitializer.factory";
import { AppComponent } from "./app.component";
import { AppConfigComponent } from "./app.config.component";
import { AppFooterComponent } from "./app.footer.component";
import { AppMainComponent } from "./app.main.component";
import { AppMenuComponent } from "./app.menu.component";
import { AppMenuitemComponent } from "./app.menuitem.component";
import { AppTopBarComponent } from "./app.topbar.component";
import { AccessComponent } from "./components/access/access.component";
import { ActivityCalenderComponent } from "./components/activities/activity-calender.component";
import { ActivityDetailComponent } from "./components/activities/activity-detail/activity-detail.component";
import { ActivityListComponent } from "./components/activities/activity-list.component";
import { AppCodeModule } from "./components/app-code/app.code.component";
import { BenefitCalculationListComponent } from "./components/benefit-calculation/benefit-calculation-list.component";
import { CertificationListComponent } from "./components/certifications/certificationlist.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { EmptyComponent } from "./components/empty/empty.component";
import { ErrorComponent } from "./components/error/error.component";
import { ProjectKanbanComponent } from "./components/kanban/project-kanban.component";
import { ProjectTaskDialogComponent } from "./components/kanban/project-task-dialog/project-task-dialog.component";
import { LandingComponent } from "./components/landing/landing.component";
import { LocaleStringResourcesComponent } from "./components/locale-string-resources/locale-string-resources.component";
import { LoginComponent } from "./components/login/login.component";
import { ProspectListComponent } from "./components/prospects/prospect-list/prospect-list.component";
import { KortByAnsvarComponent } from "./components/prospects/prospect-map/prospect-map.component";
// import { KortByRegionComponent } from "./components/neeo-bovis-kort-region/neeo-bovis-kort-region.component";
//import { KortByTeamComponent } from "./components/neeo-bovis-kort-team/neeo-bovis-kort-team.component";
import { InventoryListComponent } from "./components/inventory/consumables/inventory-list/inventory-list.component";
import { MaterialListComponent } from "./components/inventory/consumables/material-list/material-list.component";
import { LoanableMaterialListComponent } from "./components/inventory/loanable/loanable-material/loanable-material-list.component";
import { NoteEditDialogComponent } from "./components/note-edit-dialog/note-edit-dialog.component";
import { NoterListComponent } from "./components/noterlist/noter-list.component";
import { NotfoundComponent } from "./components/notfound/notfound.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { ProspectDetailActivitiesComponent } from "./components/prospects/prospect-detail/prospect-detail-activities/prospect-detail-activities.component";
import { ProspectDetailBenefitComponent } from "./components/prospects/prospect-detail/prospect-detail-benefit/prospect-detail-benefit.component";
import { ProspectDetailMasterDataComponent } from "./components/prospects/prospect-detail/prospect-detail-master-data/prospect-detail-master-data.component";
import { ProspectDetailNotesComponent } from "./components/prospects/prospect-detail/prospect-detail-notes/prospect-detail-notes.component";
import { ProspectDetailOverViewComponent } from "./components/prospects/prospect-detail/prospect-detail-overview/prospect-detail-overview.component";
import { ProspectDetailsComponent } from "./components/prospects/prospect-detail/prospect-detail.component";
import { SchedulerComponent } from "./components/scheduler/scheduler.component";
import { WikiListComponent } from "./components/superadmin/wiki/wiki-list.component";
import { TeamSchedulerComponent } from "./components/team-scheduler-component/team-scheduler.component";
import { TreatmentCoursesListComponent } from "./components/treatments/treatment-series-list.component";
import { TrialPeriodDetailComponent } from "./components/trialperiod/trail-period-detail/trail-period-detail.component";
import { ConfigService } from "./service/app.config.service";
import { MenuService } from "./service/app.menu.service";
import { CountryService } from "./service/countryservice";
import { CustomerServiceNG } from "./service/customerservice";
import { EventService } from "./service/eventservice";
import { IconService } from "./service/iconservice";
import { NodeService } from "./service/nodeservice";
import { PhotoService } from "./service/photoservice";
import { ProductService } from "./service/productservice";
//import { WeeklySchedulerComponent } from "./components/weekly-scheduler/weekly-scheduler.component";
import { FarmerDashboardComponent } from "./components/farmer-dashboard/farmer-dashboard.component";
import { TransactionsComponent } from "./components/inventory/consumables/transactions/transactions.component";
import { ProspectTrialperiodDialogComponent } from "./components/prospect-trialperiod-dialog/prospect-trialperiod-dialog.component";
import { ProspectDetailEmailListComponent } from "./components/prospects/prospect-detail/prospect-detail-emaillist/prospect-detail-emaillist.component";
import { ProspectTrialperiodSchedulerComponent } from "./components/prospects/prospect-trialperiod-scheduler/prospect-trialperiod-scheduler.component";
import { FarmEditDialogComponent } from "./components/superadmin/customer/farm-edit-dialog/farm-edit-dialog.component";
import { SuperAdminCustomerCreateEditComponent } from "./components/superadmin/customer/superadmin-customer-createedit.component";
import { SuperAdminFarmListComponent } from "./components/superadmin/customer/superadmin-customer-farmlist.component";
import { SuperAdminCustomerIdComponent } from "./components/superadmin/customer/superadmin-customer-id.component";
import { SuperAdminCustomerNoteListComponent } from "./components/superadmin/customer/superadmin-customer-notelist.component";
import { SuperAdminCustomerUserListComponent } from "./components/superadmin/customer/superadmin-customer-userlist.component";
import { SuperAdminCustomerListComponent } from "./components/superadmin/customer/superadmin-customerlist.component";
import { SuperAdminEmailListComponent } from "./components/superadmin/customer/superadmin-emaillist.component";
import { TrialPeriodCalendarComponent } from "./components/trialperiod/trail-period-detail/trial-period-calendar.component";
import { UserEditGuard } from "./components/user/user-edit.guard";
import { UserEditMainComponent } from "./components/user/user-edit/user-edit-main.component";
import { UserEditComponent } from "./components/user/user-edit/user-edit.component";
import { VeterinarianDashboardComponent } from "./components/veterinarian-dashboard/veterinarian-dashboard.component";
import { HtmlNoteEditComponent } from "./components/html-note/html-note-edit.component";
import { HtmlNoteListComponent } from "./components/html-note/html-notelist.component";

function getMsalProtectedResourcesMap() {
  const map: Map<string, Array<string>> = new Map();
  map.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);
  return map;
}

function getMsalInstance(): IPublicClientApplication {
  const msalInstance = new PublicClientApplication({
    auth: {
      clientId: "d9950879-63f2-4282-bbb6-441af9bd0510",
      authority: "https://login.microsoftonline.com/e7813d9e-785b-47cc-a883-b3d709b479f2",
      //redirectUri: "/",
      redirectUri: window.location.origin + "/login",
      postLogoutRedirectUri: "/pages/landing"
      // clientCapabilities: ["CP1"]
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false
    }
  });

  // Initialisering af MSAL-klienten
  msalInstance.initialize();

  return msalInstance;
}

export class ElHttpTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}
  getTranslation(lang: string): Observable<any> {
    let languageId = BiCountryId.DK;
    if (lang === "se") languageId = BiCountryId.SE;
    if (lang === "en") languageId = BiCountryId.EN;
    if (lang === "fi") languageId = BiCountryId.FI;
    if (lang === "no") languageId = BiCountryId.NO;
    return this.http.get(ApiRoutes.commonRoutes.localizationRoutes.getResourcesJson, {
      params: { languageId: languageId.toString() }
    });
  }
}

@NgModule({
  bootstrap: [AppComponent, MsalRedirectComponent],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppConfigComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    DashboardComponent,
    // KortByTeamComponent,
    // KortByRegionComponent,
    KortByAnsvarComponent,
    ProspectListComponent,
    NoterListComponent,
    LocaleStringResourcesComponent,
    CertificationListComponent,
    EmptyComponent,
    LandingComponent,
    LoginComponent,
    ErrorComponent,
    NotfoundComponent,
    AccessComponent,
    ProspectDetailsComponent,
    ProspectDetailMasterDataComponent,
    ProspectDetailBenefitComponent,
    ProspectDetailOverViewComponent,
    ProspectDetailNotesComponent,
    NoteEditDialogComponent,
    BenefitCalculationListComponent,
    WikiListComponent,
    ActivityListComponent,
    ActivityDetailComponent,
    ProspectDetailActivitiesComponent,
    SchedulerComponent,
    ActivityCalenderComponent,
    TeamSchedulerComponent,
    TreatmentCoursesListComponent,
    ProjectKanbanComponent,
    ProjectTaskDialogComponent,
    PrivacyPolicyComponent,
    MaterialListComponent,
    InventoryListComponent,
    TransactionsComponent,
    TrialPeriodDetailComponent,
    LoanableMaterialListComponent,
    //WeeklySchedulerComponent,
    VeterinarianDashboardComponent,
    FarmerDashboardComponent,
    ProspectTrialperiodSchedulerComponent,
    ProspectTrialperiodDialogComponent,
    ProspectDetailEmailListComponent,
    TrialPeriodCalendarComponent,
    SuperAdminCustomerListComponent,
    SuperAdminCustomerIdComponent,
    SuperAdminCustomerCreateEditComponent,
    SuperAdminCustomerUserListComponent,
    SuperAdminEmailListComponent,
    SuperAdminFarmListComponent,
    SuperAdminCustomerNoteListComponent,
    FarmEditDialogComponent,
    UserEditComponent,
    UserEditMainComponent,
    HtmlNoteEditComponent,
    HtmlNoteListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    AccordionModule,
    AutoCompleteModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CascadeSelectModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    ChipModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DropdownModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    ImageModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    KnobModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SplitButtonModule,
    SplitterModule,
    StepsModule,
    TagModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    TimelineModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeSelectModule,
    TreeTableModule,
    VirtualScrollerModule,
    AppCodeModule,
    StyleClassModule,
    ScheduleModule,
    KanbanModule,
    SyncfusionDialogModule,
    RichTextEditorModule,
    MentionModule,
    RecurrenceEditorModule,
    BiSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: ElHttpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    CoreModule.forRoot(),
    SharedModule,
    FeaturesModule,
    MsalModule.forRoot(
      getMsalInstance(),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ["user.read", "openid", "profile"]
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: getMsalProtectedResourcesMap()
      }
    )
  ],
  providers: [
    CountryService,
    CustomerServiceNG,
    EventService,
    IconService,
    NodeService,
    PhotoService,
    ProductService,
    UserService,
    MenuService,
    ConfigService,
    MessageService,
    CertificationService,
    ConfirmationService,
    {
      provide: APP_INITIALIZER,
      useFactory: neeobovisAppInitializer,
      deps: [TranslateService, UserService, CustomerService, AUTHENTICATION_SERVICE_TOKEN],
      multi: true
    },
    AppCanActivateGuard,
    AppCanDeactivateGuard,
    { provide: RouteReuseStrategy, useClass: MasterDetailReuseStrategy },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ToolbarService,
    LinkService,
    ImageService,
    HtmlEditorService,
    QuickToolbarService,
    UserEditGuard
  ]
})
export class AppModule {}
