import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser"; // Import DomSanitizer
import { HtmlNoteDto } from "@apiModels/htmlNoteDto";
import { RefType } from "@enums/RefType";
import { ActivityService } from "@globals/services/activity.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { userItems } from "@shared/interfaces-and-enums/shared-data";
import { WindowSessionStorageNames } from "@shared/variables-and-functions/WindowSessionStorageNames";
import { MessageService } from "primeng/api";
import { finalize } from "rxjs";

@UntilDestroy()
@Component({
  selector: "app-html-notelist",
  templateUrl: "./html-notelist.component.html",
  styleUrls: ["./html-notelist.component.scss"],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlNoteListComponent implements OnInit {
  @Input() refId: number | null = null;
  @Input() refTypeId: number | null = null;
  @Input() customerId: number | null = null;

  public loading = true;
  public notes: HtmlNoteDto[] = [];
  public selectedNote: HtmlNoteDto | null = null;
  public editDialogVisible: boolean = false;

  userItems = userItems;

  constructor(
    private activityService: ActivityService,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    //this.customerId = +window.sessionStorage.getItem(WindowSessionStorageNames.customerId);
    this.loadNotes();
  }

  loadNotes(): void {
    this.loading = true;

    this.activityService
      .getAllHtmlNotes(this.customerId, this.refTypeId, this.refId)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.loading = false))
      )
      .subscribe({
        next: (data: HtmlNoteDto[]) => {
          this.notes = data || [];
          this.sortNotes();
          this.cd.markForCheck();
        },
        error: error => {
          console.error("Error loading notes:", error);
          this.messageService.add({
            severity: "error",
            summary: "Fejl",
            detail: "Kunne ikke hente noter."
          });
        }
      });
  }

  private sortNotes(): void {
    this.notes = this.notes.filter(note => !note.deleted).sort((a, b) => new Date(b.dateCreatedUtc).getTime() - new Date(a.dateCreatedUtc).getTime());
  }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getUserName(userId: number): string {
    const user = this.userItems.find(u => u.value === userId);
    return user ? user.label : "Ukendt bruger";
  }

  public onDialogOpen(): void {
    console.log("Dialog opened");
  }

  public onDialogClose(): void {
    this.closeDialog();
    console.log("Dialog closed");
  }

  openNoteDialog(): void {
    const user = this.userItems.find(u => u.value === +window.sessionStorage.getItem(WindowSessionStorageNames.userId));
    this.selectedNote = {
      id: 0,
      customerId: this.customerId,
      refTypeId: RefType.Customer,
      refId: this.customerId,
      createdByUserId: +user.value,
      userName: user.label,
      dateCreatedUtc: new Date().toISOString(),
      deleted: false,
      note: ""
    };
    this.editDialogVisible = true;
    this.cd.markForCheck();
  }

  editNote(note: HtmlNoteDto): void {
    this.selectedNote = { ...note };
    this.editDialogVisible = true;
    this.cd.markForCheck();
  }

  onNoteSaved(updatedNote: HtmlNoteDto): void {
    const index = this.notes.findIndex(n => n.id === updatedNote.id);
    if (index !== -1) {
      this.notes[index] = updatedNote;
    } else {
      this.notes.unshift(updatedNote);
    }
    this.sortNotes();
    this.closeDialog();
  }

  closeDialog(): void {
    this.selectedNote = null;
    this.editDialogVisible = false;
    this.cd.detectChanges();
  }

  handleCloseDialog(): void {
    this.selectedNote = null;
    this.editDialogVisible = false;
    this.cd.detectChanges();
  }
}
